import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '../types/UserState'

export const module: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    token: '',
    refreshToken: '',
    groupToken: '',
    groupId: null,
    current: null,
    current_storecode: '',
    session_started: new Date(),
    orders_history: null,
    returned_orders: null,
    local_data_loaded: false,
    current_user_short_location: '',
    current_user_location: '',
    current_user_latitude: '',
    current_user_longitude: '',
    current_user_location_allow: true,
    shipping_addresses: null,
    rmaOrders: null,
    visitor_id: null
  },
  getters,
  actions,
  mutations
}
