import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '../types/UserState'

const getters: GetterTree<UserState, RootState> = {
  isLoggedIn (state) {
    return state.current !== null
  },
  isLocalDataLoaded: state => state.local_data_loaded,
  getUserToken (state) {
    return state.token
  },
  getOrdersHistory (state) {
    return state.orders_history ? state.orders_history.items : []
  },
  getReturnedOrders (state) {
    return state.returned_orders ? state.returned_orders : []
  },
  getUserLocationBlock (state) {
    return '<div class="md:block"><div class="footer-store-location"><h3>Location</h3><div class="lo-new"><i class="fa fa-map-marker mark-one"></i><p>'+state.current_user_location+'</p></div></div></div>';
  }
}

export default getters
