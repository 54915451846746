import Vue from 'vue'
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import LookingForState from '../types/LookingForState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { processURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import fetch from 'isomorphic-fetch'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import i18n from '@vue-storefront/i18n'
import rootStore from '@vue-storefront/core/store'
import { ReviewRequest } from '@vue-storefront/core/modules/review/types/ReviewRequest'
import Review from '@vue-storefront/core/modules/review/types/Review'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'

const actions = {
  updateLookingFor (context, {catId, SearchQuery, size = 50, sort = ''}) {
    // let searchQuery = new SearchQuery();
    let searchQuery = SearchQuery;
    // let query = '';
    return quickSearchByQuery(
      {entityType: 'category', query: searchQuery, size: size, sort: sort})
      .then((data) => {
        if (data.items.length !== 0) {
          context.commit(`updateLookingFor`, data.items)
        }
      })

    /* return quickSearchByQuery(
      { entityType: 'lookingfor', query: searchQuery, sort: 'name.keyword:asc'})
    .then((data) => {
       if (data.items.length !== 0) {
          // console.log(data.items)
          context.commit(`updateLookingFor`, data.items)
       }
    }) */

    /* let url = (config.lookingfor.endpoint)+'/lookingFor/{{catIds}}'.replace('{{catIds}}', catId)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`updateLookingFor`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      }) */
  },

  getSellerList (context) {
    let searchQuery = new SearchQuery();

    return quickSearchByQuery(
      {entityType: 'sellerlist', query: searchQuery, sort: 'entity_id:asc'})
      .then((data) => {
        if (data.items.length !== 0) {
          data.items['total'] = data.total
          context.commit(`sellerList`, data.items)
        }
      })
  },

  getLocationSortSellerList (context, {size, query}) {
    // let searchQuery = new SearchQuery();
    // console.log('searchQuerysearchQuerysearchQuery', searchQuery)
    return quickSearchByQuery(
      {entityType: 'sellerlist', query: query, sort: 'entity_id:asc',size: size})
      .then((data) => {
        if (data.items.length !== 0) {
          data.items['total'] = data.total
          context.commit(`sellerList`, data.items)
        } else {
          data.items['total'] = 0
          context.commit(`sellerList`, data.items)
        }
      })

    /* let url = (config.lookingfor.endpoint)+'/sellerList/'
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellerList`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      }) */
  },

  getCatLocationSortSellerList (context, {size, query}) {
    return quickSearchByQuery(
      {entityType: 'sellerlist', query: query, sort: 'entity_id:asc',size: size})
      .then((data) => {
        if (data.items.length !== 0) {
          data.items['total'] = data.total
          context.commit(`categoryBasedSellerList`, data.items)
        } else {
          data.items['total'] = 0
          context.commit(`categoryBasedSellerList`, data.items)
        }
      })
  },

  allKmBasedShopFilter (context, {minimum_distance = 0, best_seller = false, category_id = 0}) {
    let searchQuery = new SearchQuery();
    let data = {}
    if (best_seller) {
      let best_seller_condition = {
        'must_not': {
          'term': {'rating': 0}
        }
      }
      data = {
        'query': {
          'bool': {
            'must': {
              'match_all': {}
            },
            'filter': {
              'geo_distance': {
                'distance': minimum_distance + '.49km',
                'location_data': {
                  'lat': rootStore.state.user.current_user_latitude,
                  'lon': rootStore.state.user.current_user_longitude
                }
              }
            },
            ...best_seller_condition
          }
        },
        'script_fields': {
          'distance': {
            'script': 'doc["location_data"].planeDistance('+parseFloat(rootStore.state.user.current_user_latitude)+','+parseFloat(rootStore.state.user.current_user_longitude)+') * 0.001'
          }
        },
        '_source': true
      }
    } else if (category_id) {
      let category_condition = {
        'must_not': {
          'term': {'cat_ids': category_id}
        }
      }
      data = {
        'query': {
          'bool': {
            'must': {
              'match_all': {}
            },
            'filter': [
              {
                'geo_distance': {
                  'distance': minimum_distance + '.49km',
                  'location_data': {
                    'lat': rootStore.state.user.current_user_latitude,
                    'lon': rootStore.state.user.current_user_longitude
                  }
                }
              },
              {'term': {'cat_ids': category_id}}
            ]
          }
        },
        'script_fields': {
          'distance': {
            'script': 'doc["location_data"].planeDistance('+parseFloat(rootStore.state.user.current_user_latitude)+','+parseFloat(rootStore.state.user.current_user_longitude)+') * 0.001'
          }
        },
        '_source': true
      }
    } else {
      data = {
        'query': {
          'bool': {
            'must': {
              'match_all': {}
            },
            'filter': {
              'geo_distance': {
                'distance': minimum_distance + '.49km',
                'location_data': {
                  'lat': rootStore.state.user.current_user_latitude,
                  'lon': rootStore.state.user.current_user_longitude
                }
              }
            }
          }
        },
        'script_fields': {
          'distance': {
            'script': 'doc["location_data"].planeDistance('+parseFloat(rootStore.state.user.current_user_latitude)+','+parseFloat(rootStore.state.user.current_user_longitude)+') * 0.001'
          }
        },
        '_source': true
      }
    }
    return quickSearchByQuery(
      {entityType: 'sellerlist', size: 6000, query: data, sort: 'entity_id:desc'})
      .then((data) => {
        var response = {}
        response['category_filter'] = new Array()
        response['location_filter'] = new Array()
        response['minimum_km'] = minimum_distance
        let total_min_km_shop = 0
        response['total_min_km_pro'] = total_min_km_shop
        if (data.items.length !== 0 && minimum_distance !== 0) {
          let currlatitude = rootStore.state.user.current_user_latitude
          let currlongitude = rootStore.state.user.current_user_longitude
          let shopData = data.items
          let pro_price = []
          let all_shops = new Array()
          var vars = {};
          let categories_ids = []
          let counter_var = 0
          for (var i = 0; i < Object.keys(shopData).length; i++) {
            let shop = shopData[i]
            let isValid = true
            if (typeof currlatitude !== 'undefined' && currlatitude !== '' && typeof currlongitude !== 'undefined' && currlongitude !== '') {
              
              if (shop['curr_distance']) {
                let distanceKm = shop['curr_distance']
                if (distanceKm <= rootStore.state.config.seller.maxKmsRange) {
                  all_shops[i] = new Array()
                  all_shops[i]['id'] = shop['id']
                  all_shops[i]['distanceKm'] = distanceKm
                  total_min_km_shop++

                  //PREPARE CATEGORY FILTER
                  if (typeof shop['category'] != 'undefined' && shop['category'].length > 0) {
                    if (typeof shop['cat_ids'] != 'undefined' && shop['cat_ids'].length > 0) {
                      shop['cat_ids'] = shop['cat_ids'].map(function (x) { 
                        return parseInt(x, 10); 
                      });
                      all_shops[i]['category_ids'] = shop['cat_ids']
                    }
                    for (var j = 0; j < shop['category'].length; j++) {
                      if (typeof vars['dcount_' + shop['category'][j].id ] == 'undefined') {
                        vars['dcount_' + shop['category'][j].id ] = 1
                        if (typeof shop['category'][j].slug != 'undefined') {
                          categories_ids[shop['category'][j].slug] = counter_var
                          counter_var++
                        }
                      }
                      if (typeof categories_ids[shop['category'][j].slug] != 'undefined') {
                        response['category_filter'][categories_ids[shop['category'][j].slug]] = {'value': shop['category'][j].id, 'id': parseInt(shop['category'][j].id, 10), 'count' : vars['dcount_' + shop['category'][j].id]++, 'label': shop['category'][j].name} 
                      } 
                    }
                  }
                  //PREPARE CATEGORY FILTER

                  //PREPARE LOCATION FILTER
                  if (typeof vars['lcount_' + distanceKm ] == 'undefined') {
                    vars['lcount_' + distanceKm ] = 1
                  }
                  response['location_filter'][distanceKm] = {'id': distanceKm, 'label': distanceKm, 'count': vars['lcount_' + distanceKm]++}
                  //PREPARE LOCATION FILTER
                  
                  //ADD RATING DATA
                  if (typeof shop['review'] !== 'undefined') {
                    all_shops[i]['review'] = shop['review']
                  }
                  //ADD RATING DATA
                }
              }
            }
          }//FOR LOOP
          context.commit(`allShops`, all_shops)
          response['minimum_km'] = minimum_distance
          response['total_min_km_pro'] = total_min_km_shop
          all_shops['total_min_km_pro'] = total_min_km_shop
        }
        return response
      }).catch((err) => {
        Logger.error(err)()
      })

    /* let url = (config.lookingfor.endpoint)+'/sellerList/'
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellerList`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      }) */
  },
  addAvailableFilter (context, {key = {}, options = {}}) {
    if (key) context.commit(`addAvailableShopFilters`, {key, options})
  },
  setInitialMinimumKm (context, initialMinimumKm) {
    context.commit(`setInitialMinimumKm`, initialMinimumKm)
  },

  getBestSeller (context, {SearchQuery, size = 50, sort = ''}) {
    let searchQuery = SearchQuery;

    if (sort === '') {
      sort = 'entity_id:asc'
    }

    return quickSearchByQuery(
      {entityType: 'bestseller', query: searchQuery, sort: sort})
      .then((data) => {
        if (data.items.length !== 0) {
          context.commit(`bestSeller`, data.items)
        }
      })
    /* let url = (config.lookingfor.endpoint)+'/bestSeller/'
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`bestSeller`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      }) */
  },

  getLocationSortBestSellerList (context, {SearchQuery, size = 50, sort = ''}) {
    let searchQuery = SearchQuery;

    if (sort === '') {
      sort = 'entity_id:asc'
    }

    return quickSearchByQuery(
      {entityType: 'bestseller', query: searchQuery, sort: sort})
      .then((data) => {
        console.log('data.items', data.items)
        if (data.items.length !== 0) {
          data.items['total'] = data.total
          context.commit(`bestSeller`, data.items)
        } else {
          data.items['total'] = 0
          context.commit(`bestSeller`, data.items)
        }
      })
  },
  getSellerDetail (context, {proId}) {
    let url = (config.lookingfor.endpoint) + '/sellerbyproduct/{{proId}}'.replace('{{proId}}', proId)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellerDetail`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },

  getSellerBestPrice (context, {proId}) {
    let url = (config.lookingfor.endpoint) + '/sellerbestprice/{{proId}}'.replace('{{proId}}', proId)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellerBestPrice`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },

  getProductByRatting (context, {proId}) {
    let url = (config.lookingfor.endpoint) + '/productratting/{{proId}}'.replace('{{proId}}', proId)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`productByRatting`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },

  getReviewListing (context, {sku}) {
    let url = (config.lookingfor.endpoint) + '/reviewslist/{{sku}}'.replace('{{sku}}', sku)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`reviewListing`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },

  /**
   * Add new review
   *
   * @param context
   * @param {Review} reviewData
   * @returns {Promise<void>}
   */
  async writereview (context, reviewData: Review) {
    const review: ReviewRequest = {review: reviewData}
    let url = (config.lookingfor.endpoint) + '/writereview'

    // Vue.prototype.$bus.$emit('notification-progress-start', i18n.t('Adding a review ...'))

    // if (config.storeViews.multistore) {
    //   url = adjustMultistoreApiUrl(url)
    // }

    try {
      await fetch(processURLAddress(url), {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(review)
      })
      // Vue.prototype.$bus.$emit('notification-progress-stop')
      rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('You submitted your review for moderation.'),
        action1: { label: i18n.t('OK') }
      })
      Vue.prototype.$bus.$emit('clear-add-review-form')
      Vue.prototype.$bus.$emit('refresh-review-list')
    } catch (e) {
      Vue.prototype.$bus.$emit('notification-progress-stop')
      /* rootStore.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Something went wrong. Try again in a few seconds.'),
        action1: { label: i18n.t('OK') }
      }) */
    };
  },

  getBestDeal (context, {id, sort, query, page, limit, flag}) {
    let url = (config.lookingfor.endpoint) + '/bestdeal/{{id}}/{{sort}}/{{query}}/{{page}}/{{limit}}/{{flag}}'.replace('{{id}}', id).replace('{{sort}}', sort).replace('{{query}}', query).replace('{{page}}', page).replace('{{limit}}', limit).replace('{{flag}}', flag)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`bestDeal`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  }

}

export default actions
