import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import LookingForState from '../types/LookingForState'

export const module: Module<LookingForState, RootState> = {
  namespaced: true,
  state: {
    categories: {
      lookingfor: []
    },
    sellerList: [],
    categoryBasedSellerList: [],
    bestSeller: [],
    sellerDetail: [],
    sellerBestPrice: [],
    productByRatting: [],
    writeProductReview: [],
    reviewListing: [],
    bestDeal: [],
    allShops: [],
    initialMinimumKm: [],
    filters: {
      availablefilter: {},
      chosen: {}
    },
  },
  getters,
  actions,
  mutations
}
