import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { remove as removeAccents } from 'remove-accents'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import Vue from 'vue'
import config from 'config'
import { sha3_224 } from 'js-sha3'

/**
 * Create slugify -> "create-slugify" permalink  of text
 * @param {String} text
 */
export function slugify (text) {
  // remove regional characters
  text = removeAccents(text)

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
}

/**
 * @param relativeUrl
 * @param width
 * @param height
 * @returns {*}
 */
export function getThumbnailPath (relativeUrl, width, height) {
  if (config.images.useExactUrlsNoProxy) {
    return relativeUrl // this is exact url mode
  } else {
    let resultUrl
    if (relativeUrl && (relativeUrl.indexOf('://') > 0 || relativeUrl.indexOf('?') > 0 || relativeUrl.indexOf('&') > 0)) relativeUrl = encodeURIComponent(relativeUrl)
    let baseUrl = config.images.proxyUrl ? config.images.proxyUrl : config.images.baseUrl // proxyUrl is not a url base path but contains {{url}} parameters and so on to use the relativeUrl as a template value and then do the image proxy opertions
    if (baseUrl.indexOf('{{') >= 0) {
      baseUrl = baseUrl.replace('{{url}}', relativeUrl)
      baseUrl = baseUrl.replace('{{width}}', width)
      baseUrl = baseUrl.replace('{{height}}', height)
      resultUrl = baseUrl
    } else {
      resultUrl = `${baseUrl}${parseInt(width)}/${parseInt(height)}/resize${relativeUrl}`
    }
    return relativeUrl && relativeUrl.indexOf('no_selection') < 0 ? resultUrl : config.images.productPlaceholder || ''
  }
}

/**
 * Re-format category path to be suitable for breadcrumb
 * @param {Array} categoryPath
 */
export function formatBreadCrumbRoutes (categoryPath) {
  const breadCrumbRoutesArray = []
  for (let category of categoryPath) {
    breadCrumbRoutesArray.push({
      name: category.name,
      route_link: formatCategoryLink(category)
    })
  }
  return breadCrumbRoutesArray
}

/**
 * Return configurable product thumbnail depending on the configurable_children
 * @param {object} product
 * @param {bool} ignoreConfig
 */
export function productThumbnailPath (product, ignoreConfig = false) {
  let thumbnail = product.image
  if ((product.type_id && product.type_id === 'configurable') && product.hasOwnProperty('configurable_children') &&
    product.configurable_children.length && (ignoreConfig || !product.is_configured) &&
    ('image' in product.configurable_children[0])
  ) {
    thumbnail = product.configurable_children[0].image
    if (!thumbnail || thumbnail === 'no_selection') {
      const childWithImg = product.configurable_children.find(f => f.image && f.image !== 'no_selection')
      if (childWithImg) {
        thumbnail = childWithImg.image
      } else {
        thumbnail = product.image
      }
    }
  }
  return thumbnail
}

export function baseFilterProductsQuery (parentCategory, filters = [], location_filter = {}, id_filter = false) { // TODO add aggregation of color_options and size_options fields
  let searchProductQuery = new SearchQuery()
  searchProductQuery = searchProductQuery
    .applyFilter({key: 'visibility', value: {'in': [2, 3, 4]}})
    .applyFilter({key: 'status', value: {'in': [0, 1]}}) /* 2 = disabled, 4 = out of stock */
  if (Object.keys(location_filter).length > 0) {
    searchProductQuery = searchProductQuery
      .applyFilter({key: 'location_data', value: {'in': {'lat': parseFloat(location_filter['lat']), 'lon': parseFloat(location_filter['lon'])}}, scope: 'default', options: {"distance": location_filter['distance']} })
      searchProductQuery = searchProductQuery
      .applyFilter({key: 'geo_sort', value: {'in': {'lat': parseFloat(location_filter['lat']), 'lon': parseFloat(location_filter['lon'])}}, scope: 'default', options: {"distance": location_filter['distance']} })
  }
  if (config.products.listOutOfStockProducts === false) {
    searchProductQuery = searchProductQuery.applyFilter({key: 'stock.is_in_stock', value: {'eq': true}})
  }
  if (id_filter) {
    searchProductQuery = searchProductQuery
    .applyFilter({key: 'id', value: {'eq': -1}})
  }
  // Add available catalog filters
  for (let attrToFilter of filters) {
    searchProductQuery = searchProductQuery.addAvailableFilter({field: attrToFilter, scope: 'catalog'})
  }
  let childCats = [parentCategory.id]
  // if (parentCategory.children_data) {
  //   let recurCatFinderBuilder = (category) => {
  //     if (!category) {
  //       return
  //     }

  //     if (!category.children_data) {
  //       return
  //     }

  //     for (let sc of category.children_data) {
  //       if (sc && sc.id) {
  //         childCats.push(sc.id)
  //       }
  //       // recurCatFinderBuilder(sc)
  //     }
  //   }
  //   recurCatFinderBuilder(parentCategory)
  // }

  if(parentCategory.id == 2){
    /* let childCats1 = [parentCategory.id]
    let searchQuery = new SearchQuery();
    searchQuery = searchQuery.applyFilter({key: 'product_count', value: {'gt': 1}})
    quickSearchByQuery(
        { entityType: 'category', query: searchQuery, size: 500})
      .then((data) => {
        // console.log('here',data)
         if (data.items.length !== 0) {
          for (let sc of data.items) {
            if (sc && sc.id) {
              childCats1.push(sc.id)
            }
          }
         }
      }) */
      /*let l = ( childCats.length - 200 );
      let array1 = childCats.slice(l, 2);*/
      // childCats.length = 500;
      // console.log('abc111==',childCats.length);
      // childCats = childCats1;
  }

 
  // console.log('childCats ',childCats);
  searchProductQuery = searchProductQuery.applyFilter({key: 'category_ids', value: {'in': childCats}})
  return searchProductQuery
}

export function buildFilterProductsQuery (currentCategory, chosenFilters, defaultFilters = null, location_filter = {}) {
  let filterQr = baseFilterProductsQuery(currentCategory, defaultFilters == null ? config.products.defaultFilters : defaultFilters, Object.keys(location_filter).length > 0 ? location_filter : {})
  console.log('buildFilterProductsQuery is call')
  // add choosedn filters
  for (let code of Object.keys(chosenFilters)) {
    const filter = chosenFilters[code]
    if (filter.attribute_code !== 'price' && filter.attribute_code !== 'extension_attributes.rating' && filter.attribute_code !== 'extension_attributes.seller_distance.keyword') {
      if (filter.attribute_code === 'category_ids' && filter.currentCategoryId) {
        let new_cat_ids = [filter.currentCategoryId]
        if (filter.currentCategoryId && filter.id >= filter.currentCategoryId) {
          new_cat_ids.push(filter.id)
        }
        filterQr = filterQr.applyFilter({key: 'category_ids', value: {'eq': filter.currentCategoryId}, scope: 'catalog'})
        filterQr = filterQr.applyFilter({key: 'dynamic_cat_filter', value: {'eq': filter.id}, scope: 'catalog'})
      } else {
        filterQr = filterQr.applyFilter({key: filter.attribute_code, value: {'eq': filter.id}, scope: 'catalog'})
      }
    } else { // multi should be possible filter here?
      const rangeqr = {}
      // console.log('filter ', filter)
      if (filter.attribute_code === 'extension_attributes.rating') {
        // console.log('going to here');
        if (filter.from) {
          rangeqr['gte'] = filter.from
        }
      }
      else{
        // console.log('going to else for location filter', filter)
        if (filter.attribute_code !== 'extension_attributes.seller_distance.keyword') {
          if (filter.from) {
            rangeqr['gte'] = filter.from
          }
          if (filter.to) {
            rangeqr['lt'] = filter.to
          }
        }  
      }
      
      filterQr = filterQr.applyFilter({key: filter.attribute_code, value: rangeqr, scope: 'catalog'})
    }
  }
  
  return filterQr
}

export function once (key, fn) {
  const { process = {} } = global
  const processKey = key + '__ONCE__'
  if (!process.hasOwnProperty(processKey)) {
    // Logger.debug(`Once ${key}`, 'helper')()
    process[processKey] = true
    fn()
  }
}

export const isServer: boolean = typeof window === 'undefined'

// Online/Offline helper
export const onlineHelper = Vue.observable({
  isOnline: isServer || navigator.onLine
})

!isServer && window.addEventListener('online', () => { onlineHelper.isOnline = true })
!isServer && window.addEventListener('offline', () => { onlineHelper.isOnline = false })

export const processURLAddress = (url: string = '') => {
  if (url.startsWith('/')) return `${config.api.url}${url}`
  return url
}

/*
  * serial executes Promises sequentially.
  * @param {funcs} An array of funcs that return promises.
  * @example
  * const urls = ['/url1', '/url2', '/url3']
  * serial(urls.map(url => () => $.ajax(url)))
  *     .then(Logger.log.bind(Logger))()
  */
export const serial = async promises => {
  const results = []
  for (const item of promises) {
    const result = await item;
    results.push(result)
  }
  return results
}

export const isBottomVisible = () => {
  if (isServer) {
    return false
  }
  const scrollY = window.scrollY
  const visible = window.innerHeight
  const pageHeight = document.documentElement.scrollHeight
  const bottomOfPage = visible + scrollY >= pageHeight

  return bottomOfPage || pageHeight < visible
}

// helper to calcuate the hash of the shopping cart
export const calcItemsHmac = (items, token) => {
  return sha3_224(JSON.stringify({ items, token: token }))
}

export function getAssetsBaseUrl () {
  return config.assets.baseUrl
} 
