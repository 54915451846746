import Vue from 'vue'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import rootStore from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '../types/UserState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { UserProfile } from '../types/UserProfile'
import { isServer, processURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
// import router from '@vue-storefront/core/router'

const actions: ActionTree<UserState, RootState> = {
  async startSession (context) {
    if (isServer || context.getters.isLocalDataLoaded) return
    const cache = Vue.prototype.$db.usersCollection

    const user = await cache.getItem(`current-user`)

    if (user) {
      context.commit(types.USER_INFO_LOADED, user)
    }

    context.commit(types.USER_START_SESSION)
    context.commit(types.USER_LOCAL_DATA_LOADED, true)

    cache.getItem('current-token', (err, res) => {
      if (err) {
        Logger.error(err, 'user')()
        return
      }

      if (res) {
        context.commit(types.USER_TOKEN_CHANGED, { newToken: res })
        context.dispatch('sessionAfterAuthorized')

        if (config.usePriceTiers) {
          cache.getItem('current-user', (err, userData) => {
            if (err) {
              Logger.error(err, 'user')()
              return
            }

            if (userData) {
              context.dispatch('setUserGroup', userData)
            }
          })
        }
      } else {
        Vue.prototype.$bus.$emit('session-after-nonauthorized')
      }
      Vue.prototype.$bus.$emit('session-after-started')
    })
  },
  /**
   * Send password reset link for specific e-mail
   */
  resetPassword (context, { email }) {
    return TaskQueue.execute({ url: config.users.resetPassword_endpoint,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, type: 'customer' })
      }
    })
  },
  resetNewPassword (context, { email, token, newPassword }) {
    return TaskQueue.execute({ url: "/api/ext/looking-for/resetNewpwd",
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, resetToken: token, newPassword: newPassword })
      }
    })
  },
  verifyResetPwdToken (context, { token }) {
    return TaskQueue.execute({ url: "/api/ext/looking-for/verifyResetPwdToken/"+token,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  /**
   * Login user and return user profile and current token
   */
  login (context, { username, password }) {
    let url = config.users.login_endpoint
    if (config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }
    return fetch(processURLAddress(url), { method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: username, password: password })
    }).then(resp => { return resp.json() })
      .then((resp) => {
        if (resp.code === 200) {
          rootStore.state.userTokenInvalidateLock = 0
          context.commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta }) // TODO: handle the "Refresh-token" header
          context.dispatch('me', { refresh: true, useCache: false }).then(result => {})
          context.dispatch('getOrdersHistory', { refresh: true, useCache: false }).then(result => {})
        }
        return resp
      })
  },
  /**
   * Login user and return user profile and current token
   */
  async register (context, { email, firstname, lastname, password, token, doyenhub_mobile, verified_otp }) {
    let url = config.users.create_endpoint
    if (config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }
    return fetch(processURLAddress(url), { method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customer: { email: email, firstname: firstname, lastname: lastname, extension_attributes: {recaptcha_token: token, doyenhub_mobile: doyenhub_mobile, verified_otp: verified_otp, doyenhub_mobile_verify: 1} }, password: password, })
    }).then(resp => { return resp.json() })
  },

  /**
  * Invalidate user token
  */
  refresh (context) {
    return new Promise((resolve, reject) => {
      const usersCollection = Vue.prototype.$db.usersCollection
      usersCollection.getItem('current-refresh-token', (err, refreshToken) => {
        if (err) {
          Logger.error(err, 'user')()
        }
        let url = config.users.refresh_endpoint
        if (config.storeViews.multistore) {
          url = adjustMultistoreApiUrl(url)
        }
        return fetch(processURLAddress(url), { method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ refreshToken: refreshToken })
        }).then(resp => { return resp.json() })
          .then((resp) => {
            if (resp.code === 200) {
              context.commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta ? resp.meta : null }) // TODO: handle the "Refresh-token" header
            }
            resolve(resp)
          }).catch((exc) => reject(exc))
      })
    })
  },
  /**
   * Update user groupToken and groupId in state
   * @param context
   * @param userData
   */
  setUserGroup (context, userData) {
    if (config.usePriceTiers) {
      if (userData.groupToken) {
        context.commit(types.USER_GROUP_TOKEN_CHANGED, userData.groupToken)
      }

      if (userData.group_id) {
        context.commit(types.USER_GROUP_CHANGED, userData.group_id)
      }
    } else {
      context.commit(types.USER_GROUP_TOKEN_CHANGED, '')
      context.commit(types.USER_GROUP_CHANGED, null)
    }
  },
  /**
   * Load current user profile
   */
  me (context, { refresh = true, useCache = true } = {}) {
    return new Promise((resolve, reject) => {
      if (!context.state.token) {
        Logger.warn('No User token, user unauthorized', 'user')()
        return resolve(null)
      }
      const cache = Vue.prototype.$db.usersCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('current-user', (err, res) => {
          if (err) {
            Logger.error(err, 'user')()
            return
          }

          if (res) {
            context.commit(types.USER_INFO_LOADED, res)
            context.dispatch('setUserGroup', res)
            Vue.prototype.$bus.$emit('user-after-loggedin', res)
            rootStore.dispatch('cart/authorize')

            resolve(res)
            resolvedFromCache = true
            Logger.log('Current user served from cache', 'user')()
          }
        })
      }

      if (refresh) {
        TaskQueue.execute({ url: config.users.me_endpoint,
          payload: { method: 'GET',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          }
        })
          .then((resp: any) => {
            if (resp.resultCode === 200) {
              context.commit(types.USER_INFO_LOADED, resp.result) // this also stores the current user to localForage
              context.dispatch('setUserGroup', resp.result)
            }
            if (!resolvedFromCache && resp.resultCode === 200) {
              Vue.prototype.$bus.$emit('user-after-loggedin', resp.result)
              rootStore.dispatch('cart/authorize')
              resolve(resp)
            } else {
              resolve(null)
            }
            return resp
          })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  },
  /**
   * Update user profile with data from My Account page
   */
  async update (context, userData: UserProfile) {
    await TaskQueue.queue({
      url: config.users.me_endpoint,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(userData)
      },
      callback_event: 'store:user/userAfterUpdate'
    })
  },
  setCurrentUser (context, userData) {
    context.commit(types.USER_INFO_LOADED, userData)
  },
  /**
   * Change user password
   */
  changePassword (context, passwordData) {
    return TaskQueue.execute({ url: config.users.changePassword_endpoint,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(passwordData)
      }
    }).then((resp: any) => {
      if (resp.code === 200) {
        /* rootStore.dispatch('notification/spawnNotification', {
          type: 'success',
          message: 'Password has successfully been changed',
          action1: { label: i18n.t('OK') }
        }) */

        rootStore.dispatch('user/login', {
          username: context.state.current.email,
          password: passwordData.newPassword
        })
      } else {
        rootStore.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t(resp.result.errorMessage ? resp.result.errorMessage : 'Something went wrong, Please try again.'),
          action1: { label: i18n.t('OK') }
        })
      }
    })
  },
  clearCurrentUser (context) {
    context.commit(types.USER_TOKEN_CHANGED, '')
    context.commit(types.USER_GROUP_TOKEN_CHANGED, '')
    context.commit(types.USER_GROUP_CHANGED, null)
    context.commit(types.USER_INFO_LOADED, null)
    context.dispatch('wishlist/clear', null, {root: true})
    context.dispatch('compare/clear', null, {root: true})
    context.dispatch('checkout/savePersonalDetails', {}, {root: true})
    context.dispatch('checkout/saveShippingDetails', {}, {root: true})
    context.dispatch('checkout/savePaymentDetails', {}, {root: true})
  },
  /**
   * Logout user
   */
  logout (context, { silent = false }) {
    context.commit(types.USER_END_SESSION)
    context.dispatch('cart/disconnect', {}, { root: true })
      .then(() => { context.dispatch('clearCurrentUser') })
      .then(() => { Vue.prototype.$bus.$emit('user-after-logout') })
      .then(() => { context.dispatch('cart/clear', { recreateAndSyncCart: true }, { root: true }) })
    if (!silent) {
      /* rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t("You're logged out"),
        action1: { label: i18n.t('OK') }
      }) */
    }
  },
  /**
   * Load user's orders history
   */
  getOrdersHistory (context, { refresh = true, useCache = true }) {
    rootStore.dispatch('wishlist/load', {force: true})
    // TODO: Make it as an extension from users module
    return new Promise((resolve, reject) => {
      if (!context.state.token) {
        Logger.debug('No User token, user unathorized', 'user')()
        return resolve(null)
      }
      const cache = Vue.prototype.$db.ordersHistoryCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('orders-history', (err, res) => {
          if (err) {
            Logger.error(err, 'user')()
            return
          }

          if (res) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, res)
            Vue.prototype.$bus.$emit('user-after-loaded-orders', res)

            resolve(res)
            resolvedFromCache = true
            Logger.log('Current user order history served from cache', 'user')()
          }
        })
      }

      if (refresh) {
        return TaskQueue.execute({ url: config.users.history_endpoint,
          payload: { method: 'GET',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          }
        }).then((resp: any) => {
          if (resp.code === 200) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, resp.result) // this also stores the current user to localForage
            Vue.prototype.$bus.$emit('user-after-loaded-orders', resp.result)
          }
          if (!resolvedFromCache) {
            resolve(resp.code === 200 ? resp : null)
          }
          return resp
        })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  }, 
  userAfterUpdate (context, event) {
    if (event.resultCode === 200) {
      rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('You have saved account information successfully.'),
        action1: { label: i18n.t('OK') }
      })
      rootStore.dispatch('user/setCurrentUser', event.result)
      Vue.prototype.$bus.$emit('user-after-success-profile')
    } else if (event.resultCode === 500) {
      if (event.result && event.result.errorMessage) {
        rootStore.dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t(event.result.errorMessage),
          action1: { label: i18n.t('OK') }
        })
      } else if (typeof event.result[0].dataPath != "undefined") {
        if (event.result[0].dataPath == ".customer.firstname") {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'success',
            message: i18n.t("Please enter valid user first name"),
            action1: { label: i18n.t('OK') }
          })
        } else if (event.result[0].dataPath == ".customer.lastname") {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'success',
            message: i18n.t("Please enter valid user last name"),
            action1: { label: i18n.t('OK') }
          })
        }
      } else {
        rootStore.dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t("Something went wring, Please try again."),
          action1: { label: i18n.t('OK') }
        })
      }
      Vue.prototype.$bus.$emit('user-after-fail-profile')
      // Vue.prototype.$bus.$emit('after-fail-profile')
    }
  },
  sessionAfterAuthorized (context, event) {
    Logger.info('User session authorised ', 'user')()
    rootStore.dispatch('user/me', { refresh: navigator.onLine }, { root: true }).then((us) => {}) // this will load user cart
    rootStore.dispatch('user/getOrdersHistory', { refresh: navigator.onLine }, { root: true }).then((us) => {})
    // rootStore.dispatch('user/getReturnedOrders', { refresh: navigator.onLine }, { root: true }).then((us) => {})
  },
  setCurrentLocation (context, location){
    context.commit(types.USER_CURRENT_LOCATION, location)
  },
  setCurrentShortLocation (context, short_location){
    context.commit(types.USER_CURRENT_SHORT_LOCATION, short_location)
  },
  setCurrentLatitude (context, latitude){
    context.commit(types.USER_CURRENT_LATITUDE, latitude)
  },
  setCurrentLongitude (context, longitude){
    context.commit(types.USER_CURRENT_LONGITUDE, longitude)
  },
  setUserShippingAddresses (context, data){
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/setUserShippingAddresses",
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          "data" : {
            "location": {
              "latitude" : data.latitude,
              "longitude" : data.longitude,
              "location_name" : data.locationName,
              "default_val": data.defaultAddress,
              "customer_id" : data.customer_id
            },
            "address": {
              "firstname": data.firstName,
              "lastname": data.lastName,
              "country_id" : data.country,
              "postcode" : data.postcode,
              "city" : data.city,
              "telephone" : data.phone,
              "street" : data.street,
              "region" : data.region,
              "region_id" : data.region_id,
              "house" : data.house
            }
          }
        })
      }
    })
  },
  editUserShippingAddresses (context, data){
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/editUserShippingAddresses",
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          "data" : {
            "location": {
              "location_id" : data.location_id,
              "latitude" : data.latitude,
              "longitude" : data.longitude,
              "location_name" : data.locationName,
              "default_val": data.defaultAddress,
              "customer_id" : data.customer_id
            },
            "address": {
              "address_id": data.address_id,
              "firstname": data.firstName,
              "lastname": data.lastName,
              "country_id" : data.country,
              "postcode" : data.postcode,
              "city" : data.city,
              "telephone" : data.phone,
              "street" : data.street,
              "region" : data.region,
              "region_id" : data.region_id,
              "house" : data.house
            }
          }
        })
      }
    })
  },
  calculateGST (context, data){
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/calculategst",
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          "quote_id": parseInt(data.quote_id),
          "region_id": parseInt(data.region_id),
          "items": data.items
        })
      }
    })
  },
  calculateproductwisegst (context, data){
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/calculateproductwisegst",
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "order_id": data.order_id
        })
      }
    })
  },
  getUserShippingAddresses (context, data){
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/getUserShippingAddresses/"+data.customer_id+"/"+data.isDefaultAddr,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  deleteUserShippingAddresses (context, data){
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/deleteUserShippingAddresses/"+data.address_id+"/"+data.location_id,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  getStates (context){
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/getStates",
      payload: {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  getReturnedOrders (context, {customerId}) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/getrmaorders",
      payload: { method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          "customerId": customerId
        })
      }
    })
  },
  viewReturnedOrders (context, {rma_id, customer_id}) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/viewrmaorder/"+rma_id+"/"+customer_id,
      payload: { method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  closerma (context, {rma_id, close_rma}) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/closerma/"+rma_id+"/"+close_rma,
      payload: { method: 'PUT',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  cancelrmaorder (context, {rma_id, customer_id}) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/cancelrmaorder/"+rma_id+"/"+customer_id,
      payload: { method: 'PUT',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  getorderitems (context, {order_id, user_id}) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/getorderitems/"+order_id+"/"+user_id,
      payload: { method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  creatermaorder (context, req_body) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/creatermaorder",
      payload: { method: 'PUT',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(req_body)
      }
    })
  },
  cancelorderbycustomer (context, {order_id}) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/cancelorderbycustomer/"+order_id,
      payload: { method: 'PUT',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  getVisitorId (context) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/getVisitorId",
      payload: { method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    })
  },
  storeRecentlyViewData (context, req_body) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.lookingfor.endpoint + "/storeRecentlyViewData",
      payload: { method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(req_body)
      }
    })
  },
  sendOTP (context, req_body) {
    console.log('req_body', req_body)
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.commonApi.endpoint + "/sendOTP",
      payload: { method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(req_body)
      }
    })
  },
  verifyOTP (context, req_body) {
    // TODO: Make it as an extension from users module
    return TaskQueue.execute({ url: config.commonApi.endpoint + "/verifyOTP",
      payload: { method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(req_body)
      }
    })
  },
}

export default actions
