import { MutationTree } from 'vuex'
import Vue from 'vue' 
import BestSellerState from '../types/BestSellerState'

const mutations = {
  addAvailableShopFilters (state, {key, options = []}) {
    Vue.set(state.filters.availablefilter, key, options)
  },
  allShops (state, allShops) {
    state.allShops = allShops
  },
  setInitialMinimumKm (state, initialMinimumKm) {
    state.initialMinimumKm = initialMinimumKm
  },
}

export default mutations
