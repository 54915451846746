var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "custom-options mt-5" },
    _vm._l(_vm.product.bundle_options, function (option) {
      return _c(
        "div",
        { key: "bundleOption_" + option.option_id },
        [
          _c("product-bundle-option", {
            attrs: { option: option, "error-messages": _vm.errorMessages },
            on: { optionChanged: _vm.optionChanged },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }