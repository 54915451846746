import Product from '@vue-storefront/core/modules/catalog/types/Product'
import { MicrocartProduct } from '@vue-storefront/core/modules/cart/components/Product.ts'
import i18n from '@vue-storefront/i18n'

export const AddToCart = {
  mixins: [ MicrocartProduct ],
  name: 'AddToCart',
  data () {
    return {
      isAddingToCart: false,
      flag: 0
    }
  },
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async addToCart (product: Product, fromSellerPage = false) {
      this.isAddingToCart = true
      let items = this.$store.state.cart.cartItems;
      let CurrentProductSellerId = 0

      if(this.product.extension_attributes){
        CurrentProductSellerId = this.product.extension_attributes.seller
      }else{
        CurrentProductSellerId = this.product.seller_id
      }
      console.log('this.product.extension_attributes', this.product.extension_attributes)
      console.log('this.$store.state.cart.cartItems', this.$store.state.cart.cartItems)
      if (this.$store.state.cart.cartItems.length !== 0 && !fromSellerPage) {
        for (var i = 0; i < this.$store.state.cart.cartItems.length; i++) {
          let itemSellerId = 0
          if(this.$store.state.cart.cartItems[i].extension_attributes){
            if (typeof this.$store.state.cart.cartItems[i].extension_attributes.seller != 'undefined') {
              itemSellerId = this.$store.state.cart.cartItems[i].extension_attributes.seller
            } else if (typeof this.$store.state.cart.cartItems[i].extension_attributes.seller_id != 'undefined') {
              itemSellerId = this.$store.state.cart.cartItems[i].extension_attributes.seller_id[0][0]['id']
            }
          }else{
            itemSellerId = this.$store.state.cart.cartItems[i].seller_id
          }
          console.log('CurrentProductSellerId', CurrentProductSellerId)
          console.log('itemSellerId', itemSellerId)
          if (CurrentProductSellerId != itemSellerId) {
            this.$store.dispatch('notification/spawnNotification', {
              type: 'warning',
              message: i18n.t('Your cart contains items from other seller. Would you like to reset your cart for adding items from this seller?'),
              action2: { label: i18n.t('Cancel'), action: 'close' },
              action1: { label: i18n.t('OK'), action: this.removeCart },
              hasNoTimeout: true
            });
            this.flag = 0;
            this.isAddingToCart = false
          } else {
            this.flag = 1;
          }
        }
      } else {
        this.flag = 1;
      }
      
      if( this.flag ) {
        try {
          const diffLog = await this.$store.dispatch('cart/addItem', { productToAdd: product })

          if (diffLog) {
            if (diffLog.clientNotifications && diffLog.clientNotifications.length > 0) {
              diffLog.clientNotifications.forEach(notificationData => {
                this.notifyUser(notificationData)
              })
            }
          } else {
            this.notifyUser({
              type: 'success',
              message: this.$t('Product has been added to the cart!'),
              action1: { label: this.$t('OK') },
              action2: null
            })
          }
          return diffLog
        } catch (err) {
          this.notifyUser({
            type: 'error',
            message: err,
            action1: { label: this.$t('OK') }
          })
          return null
        } finally {
          this.isAddingToCart = false
        }
      }
    },
    async removeCart () {
      this.$bus.$emit('notification-progress-start', this.$t('Please wait ...'))
      let items = this.$store.state.cart.cartItems;
      for (var i = 0; i < items.length; i++) {
        let item = items[i];
        await this.$store.dispatch('cart/removeItem', { product: item })
      }
      this.addToCart(this.product);
      this.$bus.$emit('notification-progress-stop', {})
    },
    notifyUser (notificationData) {
      //custom logic
    }
  }
}
