import { MutationTree } from 'vuex'
import Vue from 'vue' 
import LookingForState from '../types/LookingForState'

const mutations = {
  updateLookingFor (state, data) {
    state.categories = data
  },
  sellerList (state, data) {
    state.sellerList = data
  },
  bestSeller (state, data) {
    state.bestSeller = data
  },
  sellerDetail (state, data) {
    state.sellerDetail = data
  },
  sellerBestPrice (state, data) {
    state.sellerBestPrice = data
  },
  productByRatting (state, data) {
    state.productByRatting = data
  },
  writeProductReview (state, data) {
    state.writeProductReview = data
  },
  reviewListing (state, data) {
    state.reviewListing = data
  },
  bestDeal (state, data) {
    state.bestDeal = data
  },
  addAvailableShopFilters (state, {key, options = []}) {
    Vue.set(state.filters.availablefilter, key, options)
  },
  allShops (state, allShops) {
    state.allShops = allShops
  },
  setInitialMinimumKm (state, initialMinimumKm) {
    state.initialMinimumKm = initialMinimumKm
  },
  categoryBasedSellerList (state, categoryBasedSellerList) {
    state.categoryBasedSellerList = categoryBasedSellerList
  }
}

export default mutations
