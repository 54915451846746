var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "font-thin" }, [
    _c("span", { staticClass: "font-normal" }, [
      _vm._v(_vm._s(_vm._f("htmlDecode")(_vm.label))),
    ]),
    _vm._v(":\n  "),
    _c("span", [_vm._v(_vm._s(_vm._f("htmlDecode")(_vm.value)))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }