import Vue from 'vue'
import toString from 'lodash-es/toString'
import config from 'config'

import i18n from '@vue-storefront/i18n'
import store from '@vue-storefront/core/store'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { baseFilterProductsQuery, buildFilterProductsQuery, isServer } from '@vue-storefront/core/helpers'
import { htmlDecode } from '@vue-storefront/core/filters/html-decode'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import Composite from '@vue-storefront/core/mixins/composite'
import { Logger } from '@vue-storefront/core/lib/logger'
import { mapGetters, mapActions } from 'vuex'
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

export default {
  name: 'Category',
  mixins: [Composite, onBottomScroll],
  data () {
    return {
      pagination: {
        perPage: 50,
        current: 0,
        enabled: false
      },
      lazyLoadProductsOnscroll: true,
      dataLoad: false,
      cat_chosen_filter_location: {},
      minimum: this.$store.state.config.seller.minKmsRange, // LOCATION_KM
      maximum: this.$store.state.config.seller.maxKmsRange,
      called: false,
      chosen_sort: '',
      chosen_km: this.$store.state.config.seller.minKmsRange + '.49km', // LOCATION_KM
      productLoad: true,
      sidebarFilterLoad: true,
      totalKmProducts: 0,
      createdExecuted: false,
      staticFilters: {'type_sort': {}, 'type_rating': {}}
    }
  },
  updated () {
    if (this.called === false) {
      this.called = true
      let self = this
      setTimeout(() => {
        self.setInitLocation()
        // self.getProductsAfterLocationCallback()
      }, 1000)
    }
  },
  computed: {
    ...mapGetters('category', ['getCurrentCategory', 'getCurrentCategoryProductQuery', 'getAllCategoryFilters', 'getCategoryBreadcrumbs', 'getCurrentCategoryPath']),
    products () {
      if (this.createdExecuted) {
        if (Object.keys(this.cat_chosen_filter_location).length) {
          let self = this
          let cartItem = []
          let locationsData = []
          let cartItems = {}
          for (var i in this.$store.state.cart.cartItems) {
            cartItem.push(this.$store.state.cart.cartItems[i]['id'])
            cartItems[this.$store.state.cart.cartItems[i]['id']] = this.$store.state.cart.cartItems[i]['qty']
          }
          this.$store.state.product.list.items = this.$store.state.product.list.items.filter((product, index) => {
            let pid = product['id']
            if (!cartItem.includes(pid)) {
              self.$set(product, 'qty', 1)
            } else {
              for (var k in cartItems) {
                if (parseInt(pid) === parseInt(k)) {
                  self.$set(product, 'qty', cartItems[k])
                } else {
                  self.$set(product, 'qty', 1)
                }
              }
            }
            return product
          })
        }
        return this.$store.state.product.list.items
      } else {
        return []
      }
    },
    productsCounter () {
      return (this.$store.state.product.list.items && this.createdExecuted) ? this.$store.state.product.list.items.length : 0
    },
    productsTotal () {
      return this.$store.state.product.list.total
    },
    currentQuery () {
      return this.getCurrentCategoryProductQuery
    },
    isCategoryEmpty () {
      return (!(this.$store.state.product.list.items) || this.$store.state.product.list.items.length === 0)
    },
    category () {
      return this.getCurrentCategory
    },
    categoryName () {
      return this.getCurrentCategory ? this.getCurrentCategory.name : ''
    },
    categoryId () {
      return this.getCurrentCategory ? this.getCurrentCategory.id : ''
    },
    filters () {
      let cateList = [];
      let j = 0;
      if (Object.keys(this.getAllCategoryFilters.available['extension_attributes.seller_distance.keyword']).length === 0) {
        this.getAllCategoryFilters.available['extension_attributes.seller_distance.keyword'][0] = {}
        this.getAllCategoryFilters.available['extension_attributes.seller_distance.keyword'][0].dcount = 1
        this.getAllCategoryFilters.available['extension_attributes.seller_distance.keyword'][0].id = this.minimum
        this.getAllCategoryFilters.available['extension_attributes.seller_distance.keyword'][0].label = this.minimum
        this.getAllCategoryFilters.available['extension_attributes.rating'][0] = {}
        this.getAllCategoryFilters.available['category_ids'] = []
      }
      this.getAllCategoryFilters.available['extension_attributes.seller_distance.keyword'].push({
        'dcount': 1,
        'id': this.maximum,
        'label': this.maximum
      })
      if (this.categories.length !== 0) {
        // this.getAllCategoryFilters.available.category_ids.shift(); // first element remove
        // this.getAllCategoryFilters.available.category_ids.pop(); // last element remove
        if (this.getAllCategoryFilters.available.category_ids.length !== 0) {
          for (var i = 0; i < this.getAllCategoryFilters.available.category_ids.length; i++) {
            let catId = this.getAllCategoryFilters.available.category_ids[i].id;
            if (typeof this.categories[catId] !== 'undefined') {
              this.getAllCategoryFilters.available.category_ids[i].label = this.categories[catId]
              cateList[j] = this.getAllCategoryFilters.available.category_ids[i];
              j++;
            }
          }
          this.getAllCategoryFilters.available.category_ids = cateList
        }
        return this.getAllCategoryFilters
      } else {
        return this.getAllCategoryFilters
      }
    },
    breadcrumbs () {
      return this.getCategoryBreadcrumbs
    },
    categories () {
      return this.categoriesList()
    },
    getSidebarFilterLoad () {
      return this.sidebarFilterLoad
    },
    getTotalKmProducts () {
      return this.totalKmProducts
    }
  },
  watch: {
    '$store.state.user.current_user_latitude' (value, oldValue) {
      this.peventPageRefresh()
    },
    '$route' (to, from) {
      this.peventPageRefresh()
      this.$bus.$emit('category-breadcrum-changed') // sellerslider
    }
  },
  async created () {
    this.getProductList()
    this.createdExecuted = true
  },
  preAsyncData ({ store, route }) {
    Logger.log('preAsyncData query setup')()
    const currentProductQuery = store.getters['category/getCurrentCategoryProductQuery']
    const sort = currentProductQuery && currentProductQuery.sort ? currentProductQuery.sort : config.entities.productList.sort
    store.dispatch('category/setSearchOptions', {
      populateAggregations: true,
      store: store,
      route: route,
      current: 0,
      perPage: 50,
      sort,
      filters: config.products.defaultFilters,
      includeFields: config.entities.optimize && isServer ? config.entities.productList.includeFields : null,
      excludeFields: config.entities.optimize && isServer ? config.entities.productList.excludeFields : null,
      append: false
    })
  },
  async asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data
    Logger.info('Entering asyncData in Category Page (core)')()
    try {
      if (context) context.output.cacheTags.add(`category`)
      const defaultFilters = config.products.defaultFilters
      store.dispatch('category/resetFilters')
      EventBus.$emit('filter-reset')
      await store.dispatch('attribute/list', { // load filter attributes for this specific category
        filterValues: defaultFilters, // TODO: assign specific filters/ attribute codes dynamicaly to specific categories
        includeFields: config.entities.optimize && isServer ? config.entities.attribute.includeFields : null
      })
      const parentCategory = await store.dispatch('category/single', { key: config.products.useMagentoUrlKeys ? 'url_key' : 'slug', value: route.params.slug })
      let query = store.getters['category/getCurrentCategoryProductQuery']
      let location_filter = {
        'lat': store.state.user.current_user_latitude,
        'lon': store.state.user.current_user_longitude,
        'distance': store.state.config.seller.minKmsRange + '.49km'// LOCATION_KM
      }
      if (!query.searchProductQuery) {
        store.dispatch('category/mergeSearchOptions', {
          searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters)
        })
      }
      const subloaders = await store.dispatch('category/products', query)
      if (subloaders) {
        await Promise.all(subloaders)
        await EventBus.$emitFilter('category-after-load', { store: store, route: route })
      } else {
        throw new Error('Category query returned empty result')
      }
    } catch (err) {
      Logger.error(err)()
      throw err
    }
  },
  async beforeRouteEnter (to, from, next) {
    if (!isServer && !from.name) { // Loading category products to cache on SSR render
      next(vm => {
        const defaultFilters = config.products.defaultFilters
        let parentCategory = store.getters['category/getCurrentCategory']
        // this.mergeSearchOptions(this.getAppliedFilterQueryData())
        let query = store.getters['category/getCurrentCategoryProductQuery']
        let location_filter = {
          'lat': parseFloat(store.state.user.current_user_latitude),
          'lon': parseFloat(store.state.user.current_user_longitude),
          'distance': store.state.config.seller.minKmsRange + '.49km'// LOCATION_KM
        }
        store.dispatch('category/mergeSearchOptions', {
          searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters, location_filter),
          cacheOnly: true// this is cache only request
        })
        // if (!query.searchProductQuery) {
        // }
        store.dispatch('category/products', query)
      })
    } else {
      next()
    }
  },
  beforeMount () {
    this.$bus.$on('filter-changed-category', this.onFilterChanged)
    this.$bus.$on('list-change-sort', this.onSortOrderChanged)
    this.$bus.$on('location-changed-from-map', this.locationUpdated)
    this.$bus.$on('clearFilter', this.peventPageRefresh)
    if (config.usePriceTiers) {
      this.$bus.$on('user-after-loggedin', this.onUserPricesRefreshed)
      this.$bus.$on('user-after-logout', this.onUserPricesRefreshed)
    }
  },
  beforeDestroy () {
    this.$bus.$off('list-change-sort', this.onSortOrderChanged)
    this.$bus.$off('filter-changed-category', this.onFilterChanged)
    this.$bus.$off('location-changed-from-map', this.locationUpdated)
    this.$bus.$off('clearFilter', this.peventPageRefresh)
    if (config.usePriceTiers) {
      this.$bus.$off('user-after-loggedin', this.onUserPricesRefreshed)
      this.$bus.$off('user-after-logout', this.onUserPricesRefreshed)
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.validateRoute(to)
    next()
  },
  methods: {
    ...mapActions('category', ['mergeSearchOptions']),
    peventPageRefresh () {
      this.productLoad = true
      this.$bus.$emit('reset-sort')
      this.$bus.$emit('filter-reset')
      this.$store.dispatch('category/resetFilters')
      this.$store.dispatch('category/mergeSearchOptions', {
        sort: ''
      })
      this.getProductList()
      this.setFilterBasedOnKm()
      this.sidebarFilterLoad = false
    },
    getSortObject () {
      let sort = {
        '_geo_distance': {
          'location_data': [
            parseFloat(this.$store.state.user.current_user_longitude),
            parseFloat(this.$store.state.user.current_user_latitude)
          ],
          'order': 'asc',
          'unit': 'km',
          'mode': 'min',
          'distance_type': 'arc'
        }
      }
      return sort
    },
    async getProductList () {
      let searchQuery = new SearchQuery();
      searchQuery = searchQuery.applyFilter({key: 'product_count', value: {'gt': 1}})
      this.$store.dispatch('lookingfor/updateLookingFor', {
        'catId': this.cateIds,
        'SearchQuery': searchQuery,
        'size': 500
      })
      const defaultFilters = config.products.defaultFilters
      let parentCategory = this.$store.getters['category/getCurrentCategory']
      let query = store.getters['category/getCurrentCategoryProductQuery']
      let location_filter = {
        'lat': parseFloat(this.$store.state.user.current_user_latitude),
        'lon': parseFloat(this.$store.state.user.current_user_longitude),
        'distance': this.chosen_km
      }
      store.dispatch('category/mergeSearchOptions', {
        searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters, location_filter),
        sort: ''
      })
      // searchQuery = searchQuery.applyFilter({key: 'geo_sort', value: {'gt': 1}, scope: 'default'})
      await store.dispatch('category/products', query)
      this.productLoad = false
    },
    async setInitLocation () {
      if (this.minimum > 0) {
        let loca = ''
        if (this.$children) {
          loca = this.$children.find(child => { return child.$options._componentTag === 'product-location-selector'; })
          if (typeof loca !== 'undefined' && loca.defaultVal !== this.minimum) {
            if (loca.maxVal <= this.maximum) {
              if (this.filters.available['extension_attributes.seller_distance.keyword']) {
                // this.filters.available['extension_attributes.seller_distance.keyword']['0']['id'] = this.maximum += 1
                // this.filters.available['extension_attributes.seller_distance.keyword']['0']['label'] = this.maximum += 1
                this.filters.available['extension_attributes.seller_distance.keyword']['0']['id'] = this.$store.state.config.seller.maxKmsRange
                this.filters.available['extension_attributes.seller_distance.keyword']['0']['label'] = this.$store.state.config.seller.maxKmsRange
              }
            }
            this.$children.defaultVal = this.minimum
            this.$children.selectVal = this.minimum
          }
        }
      }
      this.productLoad = false
      await this.setFilterBasedOnKm()
      this.sidebarFilterLoad = false
    },
    onBottomScroll () {
      // this.pullMoreProducts()
    },
    categoriesList () {
      let catList = []
      for (var i = 0; i < this.$store.state.lookingfor.categories.length; i++) {
        let catId = this.$store.state.lookingfor.categories[i].id;
        let catName = this.$store.state.lookingfor.categories[i].name;
        catList[catId] = catName;
      }
      // catList = catList.filter(Boolean);

      return catList;
    },
    bottomVisible () {
      const scrollY = Math.ceil(window.scrollY)
      const visible = window.innerHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    pullMoreProducts () {
      if (typeof navigator !== 'undefined' && !navigator.onLine) return
      let current = this.getCurrentCategoryProductQuery.current + this.getCurrentCategoryProductQuery.perPage
      this.mergeSearchOptions({
        append: true,
        route: this.$route,
        store: this.$store,
        current
      })
      this.pagination.current = this.getCurrentCategoryProductQuery.current
      this.pagination.perPage = this.getCurrentCategoryProductQuery.perPage
      if (this.getCurrentCategoryProductQuery.current <= this.productsTotal) {
        let location_filter = {
          'lat': parseFloat(this.$store.state.user.current_user_latitude),
          'lon': parseFloat(this.$store.state.user.current_user_longitude),
          'distance': this.chosen_km
        }
        this.mergeSearchOptions({
          searchProductQuery: buildFilterProductsQuery(this.category, this.filters.chosen, null, location_filter)
        })
        return this.$store.dispatch('category/products', this.getCurrentCategoryProductQuery)
      }
    },
    async onFilterChanged (filterOption) {
      if (filterOption.attribute_code === 'extension_attributes.seller_distance.keyword') {
        this.chosen_km = filterOption.id + '.49km'
        // TODO:: DO NOT RESET RATING FILTER ON KM CHANGE
        if (this.filters.chosen['extension_attributes.rating'] != null) {
          let rating_filter = this.filters.chosen['extension_attributes.rating']
          this.filters.chosen = []
          this.filters.chosen['extension_attributes.rating'] = rating_filter
        } else {
          this.filters.chosen = []
        }
      }
      // this.productLoad = true
      // this.dataLoad = true;
      this.pagination.current = 0
      if (this.filters.chosen[filterOption.attribute_code] && ((toString(filterOption.id) === toString(this.filters.chosen[filterOption.attribute_code].id)) || filterOption.id === this.filters.chosen[filterOption.attribute_code].id)) { // for price filter it's a string
        Vue.delete(this.filters.chosen, filterOption.attribute_code)
      } else {
        Vue.set(this.filters.chosen, filterOption.attribute_code, filterOption)
        if (filterOption.attribute_code === 'category_ids') {
          this.filters.chosen.category_ids.currentCategoryId = this.getCurrentCategory.id
        }
      }
      let location_filter = {
        'lat': parseFloat(this.$store.state.user.current_user_latitude),
        'lon': parseFloat(this.$store.state.user.current_user_longitude),
        'distance': this.chosen_km
      }
      let filterQr = buildFilterProductsQuery(this.category, this.filters.chosen, null, location_filter)

      console.log('onFilterChanged', filterOption)

      const filtersConfig = Object.assign({}, this.filters.chosen) // create a copy because it will be used asynchronously (take a look below)
      this.mergeSearchOptions({
        populateAggregations: false,
        searchProductQuery: filterQr,
        current: this.pagination.current,
        perPage: this.pagination.perPage,
        configuration: filtersConfig,
        append: false,
        // includeFields: null,
        includeFields: config.entities.optimize && isServer ? config.entities.productList.includeFields : null,
        excludeFields: null
      })
      // console.log(this.getCurrentCategoryProductQuery);
      if (filterOption.attribute_code === 'extension_attributes.seller_distance.keyword') {
        this.cat_chosen_filter_location = filterOption
      }
      await this.$store.dispatch('category/products', this.getCurrentCategoryProductQuery).then((res) => {
      }) // because already aggregated
      this.totalKmProducts = this.$store.state.product.list.total
      // this.productLoad = false
      // this.sidebarFilterLoad = false
      // this.dataLoad = false;
    },
    onSortOrderChanged (param) {
      this.dataLoad = true;
      this.pagination.current = 0
      let str = param.attribute
      var arr = str.split(':');
      let sort = ''
      if (arr.length === 2) {
        sort = arr[0] + ':' + arr[1]
      } else {
        sort = arr[0] + ':asc'
      }
      if (this.chosen_sort === sort) {
        param.attribute = 'updated_at:desc'
      }
      this.chosen_sort = sort
      if (param.attribute) {
        const filtersConfig = Object.assign({}, this.filters.chosen) // create a copy because it will be used asynchronously (take a look below)
        let location_filter = {
          'lat': parseFloat(this.$store.state.user.current_user_latitude),
          'lon': parseFloat(this.$store.state.user.current_user_longitude),
          'distance': this.chosen_km
        }
        let filterQr = buildFilterProductsQuery(this.category, this.filters.chosen, null, location_filter)
        this.mergeSearchOptions({
          sort: param.attribute,
          searchProductQuery: filterQr,
          current: this.pagination.current,
          perPage: this.pagination.perPage,
          configuration: filtersConfig,
          append: false,
          // includeFields: null,
          includeFields: config.entities.optimize && isServer ? config.entities.productList.includeFields : null,
          excludeFields: null
        })
        this.$store.dispatch('category/products', this.getCurrentCategoryProductQuery).then((res) => {
        })
        this.dataLoad = false;
      } else {
        this.notify()
      }
      this.dataLoad = false;
    },
    validateRoute (route = this.$route) {
      this.$store.dispatch('category/resetFilters')
      this.$bus.$emit('filter-reset')

      this.$store.dispatch('category/single', { key: config.products.useMagentoUrlKeys ? 'url_key' : 'slug', value: route.params.slug }).then(category => {
        if (!category) {
          this.$router.push(this.localizedRoute('/'))
        } else {
          this.pagination.current = 0
          let searchProductQuery = baseFilterProductsQuery(this.getCurrentCategory, config.products.defaultFilters)
          this.$bus.$emit('current-category-changed', this.getCurrentCategoryPath)
          this.mergeSearchOptions({ // base prototype from the asyncData is being used here
            current: this.pagination.current,
            perPage: this.pagination.perPage,
            store: this.$store,
            route: this.$route,
            append: false,
            populateAggregations: true
          })
          if (!this.getCurrentCategoryProductQuery.searchProductQuery) {
            this.mergeSearchOptions({
              searchProductQuery
            })
          }
          this.$store.dispatch('category/products', this.getCurrentCategoryProductQuery)
          this.$bus.$emitFilter('category-after-load', { store: this.$store, route: route })
        }
      }).catch(err => {
        if (err.message.indexOf('query returned empty result') > 0) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t('The product, category or CMS page is not available in Offline mode. Redirecting to Home.'),
            action1: { label: i18n.t('OK') }
          })
          this.$router.push(localizedRoute('/', currentStoreView().storeCode))
        }
      })
    },
    onUserPricesRefreshed () {
      const defaultFilters = config.products.defaultFilters
      this.$store.dispatch('category/single', {
        key: config.products.useMagentoUrlKeys ? 'url_key' : 'slug',
        value: this.$route.params.slug
      }).then((parentCategory) => {
        if (!this.getCurrentCategoryProductQuery.searchProductQuery) {
          this.mergeSearchOptions({
            searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters),
            skipCache: true
          })
        }
        this.$store.dispatch('category/products', this.getCurrentCategoryProductQuery)
      })
    },
    locationUpdated (location) {
      if (location.lat && location.lng) {
        this.cat_chosen_filter_location = {'lat': location.lat, 'lng': location.lng}
        if (this.$children) {
          let categoryLocation = this.$children.find(child => { return child.$options._componentTag === 'product-location-selector'; })
          if (categoryLocation) {
            categoryLocation.lat = location.lat
            categoryLocation.lng = location.lng
          }
        }
      }
    },
    getProductsAfterLocationCallback () {
      const defaultFilters = config.products.defaultFilters
      let parentCategory = this.$store.getters['category/getCurrentCategory']
      let query = this.$store.getters['category/getCurrentCategoryProductQuery']
      if (!query.searchProductQuery) {
        this.$store.dispatch('category/mergeSearchOptions', {
          searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters),
          cacheOnly: true// this is cache only request
        })
      }
      this.$store.dispatch('category/products', query)
    },
    async setFilterBasedOnKm (minimum_km, kmChanged) {
      const defaultFilters = config.products.defaultFilters
      let parentCategory = this.$store.getters['category/getCurrentCategory']
      let query = this.$store.getters['category/getCurrentCategoryProductQuery']
      let location_filter = {
        'lat': parseFloat(this.$store.state.user.current_user_latitude),
        'lon': parseFloat(this.$store.state.user.current_user_longitude),
        'distance': (typeof minimum_km !== 'undefined') ? minimum_km : this.chosen_km
      }
      // if (!query.searchProductQuery) {
      //   console.log('location_filterlocation_filterlocation_filter', location_filter)
      // }
      this.$store.dispatch('category/mergeSearchOptions', {
        includeFields: config.entities.optimize && isServer ? config.entities.productList.includeFields : null,
        excludeFields: null,
        searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters, location_filter),
        cacheOnly: true// this is cache only request
      })
      let response = await this.$store.dispatch('category/allProducts', query)
      // SET PRICE FILTER BASED ON CHANGED KM
      if (typeof response !== 'undefined') {
        if (response.price_filter) {
          this.getAllCategoryFilters.available.price = response.price_filter
        } else {
          this.getAllCategoryFilters.available.price = []
        }
        // SET CATEGORY FILTER BASED ON CHANGED KM
        if (response.category_filter) {
          this.getAllCategoryFilters.available.category_ids = response.category_filter
        } else {
          this.getAllCategoryFilters.available.category_ids = []
        }
        // SET COLOR FILTER BASED ON CHANGED KM
        if (response.color_filter) {
          this.getAllCategoryFilters.available.color = response.color_filter
        } else {
          this.getAllCategoryFilters.available.color = []
        }
        // SET SIZE FILTER BASED ON CHANGED KM
        if (response.size_filter) {
          this.getAllCategoryFilters.available.size = response.size_filter
        } else {
          this.getAllCategoryFilters.available.size = []
        }
        if (typeof response.total_min_km_pro !== 'undefined') {
          this.totalKmProducts = response.total_min_km_pro
        }
        if (typeof kmChanged !== 'undefined' && kmChanged) {
          this.productLoad = false
          this.sidebarFilterLoad = false
        }
      }
    }
  },
  metaInfo () {
    const storeView = currentStoreView()
    return {
      link: [
        { rel: 'amphtml',
          href: this.$router.resolve(localizedRoute({
            name: 'category-amp',
            params: {
              slug: this.category.slug
            }
          }, storeView.storeCode)).href
        }
      ],
      title: htmlDecode(this.category.meta_title || this.categoryName),
      meta: this.category.meta_description ? [{ vmid: 'description', name: 'description', content: htmlDecode(this.category.meta_description) }] : []
    }
  }
}
