<template>
  <div class="row gutter-md justify-center md:justify-start product-listing-sec">
    <!-- <div
      v-for="product in products"
      :key="product.id"
      class="col-12 col-sm-6"
      :class="['md:col-' + col_md ,'lg:col-' + col_lg, vtype]"
    > -->
    <div
      v-for="product in products"
      class="col-12 col-sm-6"
      :class="['md:col-' + col_md ,'lg:col-' + col_lg, vtype]"
    >
      <product-tile :product="product" :showSellerDetails="showSellerDetails" :sellerDetailPage="sellerDetailPage"/>
    
    </div>
  </div>
</template>

<script>
import ProductTile from 'theme/components/core/ProductTile'
export default {
  name: 'ProductListing',
  components: {
    ProductTile
  },
  props: {
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    showSellerDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    sellerDetailPage: {
      type: Boolean,
      required: false,
      default: false
    },
    col_md: {
      type: Number,
      required: false,
      // default: 4
      default: 6
    },
    col_lg: {
      type: Number,
      required: false,
      default: 4
    },
    vtype:{
      type: String,
      required: false,
      default: 'grid'
    }
  }
}
</script>
