var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("raw-content", [
    _vm._v(
      "\n  This page is using empty layout set in routes + no html template\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }