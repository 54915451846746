var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quanitity-block", class: "qty-input__" + _vm.size },
    [
      _c(
        "label",
        { staticClass: "product-label", attrs: { for: "quantity" } },
        [_vm._v(_vm._s(_vm.$t("Quantity")))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex h-full" }, [
        _c("div", { staticClass: "input-number-controls" }, [
          _c(
            "button",
            {
              staticClass: "inc-count",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.decrementEvent.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-minus" })]
          ),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "text",
              min: "0",
              id: _vm.id,
              name: _vm.name,
              focus: "",
              product_id: _vm.product_id,
            },
            domProps: { value: _vm.value },
            on: {
              input: _vm.inputEvent,
              focus: function ($event) {
                return _vm.$emit("focus")
              },
              blur: function ($event) {
                return _vm.$emit("blur")
              },
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.keyupEnter.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  return _vm.incrementEvent.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  return _vm.decrementEvent.apply(null, arguments)
                },
                _vm.keyup,
              ],
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "inc-count",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.incrementEvent.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-plus" })]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }