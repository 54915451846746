import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import RecentlyViewedState from '../types/RecentlyViewedState'
import { cacheStorage } from '../'

const actions: ActionTree<RecentlyViewedState, RootState> = {
  load ({ commit }) {
    cacheStorage.getItem('recently-viewed', (err, storedItems) => {
      if (err) throw new Error(err)
      if (storedItems != null && storedItems.length > 0) {
        commit(types.RECENTLY_VIEWED_LOAD, storedItems)
        // let removeId = []
        // for (var i = 0; i < storedItems.length; i++) {
        //   removeId.push(storedItems[i].id)
        // }
        // if (removeId.length > 0) {
        //   let new_recentlyviewed = []
        //   rootStore.dispatch('product/getProductApprovalStatus', {
        //     product_id_list: removeId
        //   }).then((res) => {
        //     if (res.code == 200) {
        //       for (var i = 0; i < storedItems.length; i++) {
        //         let id = storedItems[i].id.toString()
        //         if (res.result.indexOf(id) >= 0) {
        //           //TODO:: CALCULATE DISTANCE
        //           new_recentlyviewed.push(storedItems[i])
        //         }
        //       }
        //     }
        //     commit(types.RECENTLY_VIEWED_LOAD, new_recentlyviewed)
        //   })
        // } else {
        //   commit(types.RECENTLY_VIEWED_LOAD, storedItems)
        // }
      }
    }) 
  },
  addItem ({ commit }, product) {
    product.currLatitude = localStorage.getItem('currLatitude')
    product.currLongitude = localStorage.getItem('currLongitude')
    product.currentShortAddress = localStorage.getItem('currentShortAddress')
    commit(types.RECENTLY_VIEWED_ADD_ITEM, { product })
  } 
}

export default actions
