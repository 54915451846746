<template>
  <div :class="'qty-input__' + size" class="quanitity-block">
    <label class="product-label" for="quantity">{{ $t('Quantity') }}</label>
    <div class="flex h-full">
      
      <div class="input-number-controls">
        <button @click.prevent="decrementEvent" class="inc-count">
          <i class="fa fa-minus"></i>
        </button>
        <input
        type="text"
        min="0"
        :id="id"
        :name="name"
        focus
        :product_id="product_id"
        :value="value"
        @input="inputEvent"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @keyup.enter="keyupEnter"
        @keyup.up="incrementEvent"
        @keyup.down="decrementEvent"
        @keyup="keyup"
      >
        <button @click.prevent="incrementEvent" class="inc-count">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QtyInput',
  props: {
    id: {
      type: String,
      default: 'quantity',
      required: false
    },
    name: {
      type: String,
      default: 'quantity',
      required: false
    },
    value: {
      type: [String, Number],
      default: 1,
      required: false
    },
    size: {
      type: String,
      default: 'md',
      required: false
    },
    product_id: {
      type: [String, Number],
      required: false
    }
  },
  data () {
    return {
      currprod:{}
    }
  },
  mounted() {
    if(this.$store.state.cart.cartItems.length !== 0) {
      for (var i = 0; i < this.$store.state.cart.cartItems.length; i++) {
        let item = this.$store.state.cart.cartItems[i]
        if(item.id == this.product_id){
          this.currprod = item
        }
      }
    }
    this.$bus.$on('cart-before-add', this.afterAddToCart)
  },
  methods: {
    keyup ($event) {
      this.$emit('keyup', $event)
    },
    keyupEnter ($event) {
      let value = parseInt($event.target.value)
      if (isNaN(value)) {
        value = 0
      }
      this.$emit('keyup.enter', value)
    },
    inputEvent ($event) {
      let value = parseInt($event.target.value)
      if (isNaN(value)) {
        value = 0
      }
      this.$emit('input', value)
    },
    async incrementEvent ($event) {
      let value = parseInt(this.value)
      if (isNaN(value)) {
        value = 0
      }
      this.$emit('input', value + 1)
      if(this.currprod.id == this.product_id){
        let show_qty = value + 1
        this.$bus.$emit('notification-progress-start', this.$t('Adding products to cart ...'))
        await this.$store.dispatch('cart/updateQuantity', { product: this.currprod, qty: value+1 })
        await this.$store.dispatch('cart/sync', { forceClientState: true })
        this.$bus.$emit('notification-progress-stop', {})
      }
    },
    async decrementEvent ($event) {
      let value = parseInt(this.value)
      if (isNaN(value)) {
        value = 0
      }
      this.$emit('input', value > 1 ? value - 1 : 1)
      if(this.currprod.id == this.product_id){
          let show_qty = value > 1 ? value - 1 : 1
          this.$bus.$emit('notification-progress-start', this.$t('Removing product from cart ...'))
          await this.$store.dispatch('cart/updateQuantity', { product: this.currprod, qty: value > 1 ? value - 1 : 1 })
          await this.$store.dispatch('cart/sync', { forceClientState: true })
          this.$bus.$emit('notification-progress-stop', {})
      }
    },
    afterAddToCart ($event){
      this.currprod = $event.product
    }
  }
}
</script>

