<template>
  <div
    class="product w-full pb-2 md:pb-5"
    v-observe-visibility="visibilityChanged"
  >
    <router-link
      class="block no-underline product-link"
      :to="localizedRoute({
        name: product.type_id + '-product',
        params: {
          parentSku: product.parentSku ? product.parentSku : product.sku,
          slug: product.slug,
          childSku: product.sku
        }
      })"
      data-testid="productLink"
    >
    <!-- sales tag -->
    <!-- <span class="product-sale" v-if="showSellerDetails && product.special_price && product.priceInclTax && parseFloat(product.priceInclTax) > 0 && !onlyImage" > {{ $t('Sale') }} </span> -->
    <span class="product-sale" v-if="showSpecialPrice && product.special_price && product.priceInclTax && parseFloat(product.priceInclTax) > 0 && !onlyImage" > {{ $t('Sale') }} </span>
    <!-- end tag -->

      <div
        class="product-image w-full relative"
        :class="[{ sale: labelsActive && isOnSale }, { new: labelsActive && isNew }]"
      >
        <img
          :alt="product.name"
          :src="thumbnailObj.loading"
          v-lazy="thumbnailObj"
          height="300"
          width="310"
          data-testid="productImage"
          :class="{ 'default-image': hoverThumbnail !== null }"
        >
        <img
          v-if="hoverThumbnail !== null"
          :alt="product.name"
          :src="hoverThumbnailObj.loading"
          v-lazy="hoverThumbnailObj"
          height="300"
          width="310"
          data-testid="productHoverImage"
          class="hover-image"
          @error="imageUrlAlt"
        >
      </div>

      <div class="seller-product-info">
          <div v-if="showSellerDetails && product && product.extension_attributes && product.extension_attributes.seller_id && product.extension_attributes.seller_id[0][0] ">
          <p class="product-name mb-0 font-medium text-grey-dark mt-3 pro-name-seller" v-if="!onlyImage">
            <!-- {{ product.name | htmlDecode }} -->
            {{ truncate(product.name ,18, '...') }}
          </p>
          <p v-if="product.extension_attributes.seller_id[0][0].shop_title" class="category-name mb-0 font-medium text-grey-dark mt-3">
            {{ truncate(capitalizeFirstLetter(product.extension_attributes.seller_id[0][0].shop_title) ,18, '...') }}
          </p>
          <!-- <p class="seller-contact-no" v-if="product.extension_attributes.seller_id[0][0].contact_number">{{ product.extension_attributes.seller_id[0][0].contact_number }}</p>
          <p v-if="product.extension_attributes.seller_id[0][0].company_locality" class="seller-address">{{ product.extension_attributes.seller_id[0][0].company_locality }}</p> -->

        </div>
        <div v-else>
            <p class="product-name mb-0 font-medium text-grey-dark mt-3" v-if="!onlyImage">
              <!-- {{ product.name | htmlDecode }} -->
              {{ truncate(product.name ,18, '...') }}
            </p>
        </div>

        
        <div class="rating-block product-rating" >
              <div class="ratings">
                <div class="empty-stars" :style="productEmptyRatting" ></div>
                <div class="full-stars" :style="productRatting" ></div>
              </div>
              <div class="location-block" v-if="from && (from == 'deal_slider' ||  from == 'related_product')">
                <span> 
                  <img class="location-icon location-mark" :src="$config.assets.baseUrl+'/assets/place.png'"/>
                  <p class="seller-distance mark-distance" v-if="product.curr_distance != null">{{product.curr_distance + ' Km'}}</p>
                </span>
              </div>
              <template v-else>
                <div :class="($route.name == 'home' || $route.name == 'checkout') ? 'location-block-km' : ($route.name == 'lookingfor' || $route.name == 'all-deals' || $route.name == 'urldispatcher-all-catagory-2') ? 'mark-disc-cover' : ''" >
                  <span class="curr_distance mark-distance" v-if="product.curr_distance && product.curr_distance <= $store.state.config.seller.maxKmsRange">{{ product.curr_distance + ' Km' }}</span>
                  <span class="location-span" v-if="product.curr_distance && product.curr_distance <= $store.state.config.seller.maxKmsRange">
                    <img class="location-icon location-mark location-pro" :src="$config.assets.baseUrl+'/assets/place.png'"/>
                  </span>
                </div>
              </template>
        </div>
        <div class="mt-1 text-grey-dark font-medium price-section">
          <b class="mrp-tag">MRP:</b>
          <div v-show="showSpecialPrice">
            <span
              class="line-through"
              v-if="product.special_price && parseFloat(product.originalPriceInclTax) > 0 && !onlyImage"
            >
              <b class="rs">Rs</b> {{ product.originalPriceInclTax | price }}
            </span>
            <span
              class="text-primary mr-2 actual-price"
              v-if="product.special_price && product.priceInclTax && parseFloat(product.priceInclTax) > 0 && !onlyImage"
            ><b class="rs"> Rs </b>{{ product.priceInclTax | price }}</span>
          </div>

          <div v-show="!showSpecialPrice">
            <span class="actual-price" v-if="!product.special_price && parseFloat(product.priceInclTax) > 0 && !onlyImage" >
              <b class="rs">Rs</b> {{ product.priceInclTax | price }}
            </span>
          </div>

          <div v-if="sellerDetailPage && !product.priceInclTax && !product.originalPriceInclTax && !onlyImage">
            <span class="1" v-if="showSpecialPrice && product.special_price && parseFloat(product.special_price) > 0 && !product.price"><b class="rs">Rs</b> {{ product.special_price | price }}</span>
            <span class="2" v-if="product.price && parseFloat(product.price) > 0 && !product.special_price"><b class="rs">Rs</b> {{ product.price | price }}</span>
            <span class="2" v-if="product.price && parseFloat(product.price) > 0 && product.special_price && parseFloat(product.special_price) > 0 && showSpecialPrice"><b class="rs">Rs</b> {{ product.special_price | price }}</span>
          </div>

        </div>
      </div>
    </router-link>
    <br class="page-br" /> 
    <div class="quanty-container-new">
        <div>
          <button :class="{ added : this.active_el.added_into_cart }" v-on:click="$store.state.user.current !== null ? quickAddToCart(product) : goToLogin()" class="add-quant-btn" href="#">Add<i class="fa fa-shopping-cart"></i></button>
          <div class="input-group" :class="{ qty_full_width : this.active_el.show_qty }">
            <button :disabled="qtyupdating" v-on:click="decrementEvent(product)" class="inc-dec-numb">-</button>
              <input v-if="from != ''" :ref="'qty'+product.id" type="text" :id="from+'_quantity' + product.id" name="quantity" placeholder="1" :value="product.qty">
              <input v-else :ref="'qty'+product.id" type="text" :id="'quantity' + product.id" name="quantity" placeholder="1" :value="product.qty ? product.qty : 1">
            <button :disabled="qtyupdating" v-on:click="incrementEvent(product)" class="inc-dec-numb">+</button>
          </div>
        </div>
       </div> 
  </div>
</template>

<script> 
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import { AddToCart } from '@vue-storefront/core/modules/cart/components/AddToCart'
import { MicrocartProduct } from '@vue-storefront/core/modules/cart/components/Product.ts'
import moment from 'moment'
export default {
  mixins: [ProductTile, AddToCart, MicrocartProduct],
  data () {
    return {
      active_el: {
        product_id: {},
        added_into_cart: {},
        show_qty: {},
        added_qty: 1
      },
      qtyupdating: false,
      placeholder: this.$store.state.config.assets.baseUrl+'/assets/spinner.gif'
    }
  },
  mounted () {
    this.active_el.product_id = this.product.id
    this.active_el.added_into_cart = false
    this.active_el.show_qty = false
    if (typeof this.product.qty === 'undefined') {
      this.$set(this.product, 'qty', 1)
      this.product.qty = 1
    }

    if (this.$store.state.cart.cartItems.length !== 0) {
      for (var i = 0; i < this.$store.state.cart.cartItems.length; i++) {
        let item = this.$store.state.cart.cartItems[i]
        if (item.id == this.product.id) {
          this.active_el.added_into_cart = true
          this.active_el.show_qty = true
          // this.$set(this.product,'qty',item.qty)
        }
      }
    }
    this.$bus.$on('cart-after-itemchanged', this.updateCartAndQty)
    // this.$bus.$on('cart-after-delete', this.afterDeleteItem)
    this.$bus.$on('custom-cart-before-delete', this.customAfterDeleteItem)
  },
  props: {
    labelsActive: {
      type: Boolean,
      required: false,
      default: true
    },
    onlyImage: {
      type: Boolean,
      required: false,
      default: false
    },
    showSellerDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    sellerDetailPage: {
      type: Boolean,
      required: false,
      default: false
    },
    from: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    showSpecialPrice () {
      if(this.product.special_price && parseFloat(this.product.originalPriceInclTax) > 0) {
        let today = new Date();
        let from_date = this.product.special_from_date
        let to_date = this.product.special_to_date
        if (from_date && to_date) {
          from_date = new Date(from_date)
          to_date = new Date(to_date)
          return moment(today).isBetween(from_date, to_date)
        }
        return false
      }
      return false
    },
    hoverThumbnail () {
      if (this.product.media_gallery) {
        let images = this.product.media_gallery.filter(item => item.typ === 'image')
        if (images.length > 1) {
          let thumbnail = images[images.length - 1].image
          for (let i = 0; i < images.length; i++) {
            if (images[i].lab === 'alternative') {
              thumbnail = images[i].image
              break
            }
          }
          return this.getThumbnail(thumbnail, 310, 300)
        }
      }
      return null
    },
    hoverThumbnailObj () {
      return {
        src: this.hoverThumbnail,
        loading: this.thumbnail,
        error: this.thumbnail
      }
    },
    productRatting () {
      if (typeof this.product.extension_attributes !== 'undefined' && typeof this.product.extension_attributes.rating !== 'undefined') {
        // console.log(this.product.name,this.product.extension_attributes.rating)
        let width = this.product.extension_attributes.rating
        // width = width / 2
        return width ? `width: ${width}%` : `width: 0%`
      } else if (typeof this.product.rating !== 'undefined') {
        let width = this.product.rating
        return width ? `width: ${width}%` : `width: 0%`
      } else {
        return `width: 0%`
      }
    },
    productEmptyRatting () {
      if (typeof this.product.extension_attributes !== 'undefined' && typeof this.product.extension_attributes.rating !== 'undefined') {
        // console.log(this.product.name,this.product.extension_attributes.rating)
        let width = this.product.extension_attributes.rating
        // width = Math.round(width / 2)
        // width = width / 2
        return width ? `min-width: ${width}%` : `min-width: 0%`
      } else {
        return `min-width: 0%`
      }
    }
  },
  methods: {
    goToLogin () {
      this.$store.commit('ui/setAuthElem', 'login')
      this.$bus.$emit('modal-show', 'modal-signup')
    },
    imageUrlAlt (){
      return this.placeholder
    },
    truncate: function (text, len = 10, suffix) {
      if (typeof text !== 'undefined') {
        if (text.length > len) {
          return text.substring(0, len) + suffix;
        } else {
          return text;
        }
      }

      return '';
    },
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        if (rootStore.state.config.products.configurableChildrenStockPrefetchDynamic && rootStore.products.filterUnavailableVariants) {
          const skus = [this.product.sku]
          if (this.product.type_id === 'configurable' && this.product.configurable_children && this.product.configurable_children.length > 0) {
            for (const confChild of this.product.configurable_children) {
              const cachedItem = rootStore.state.stock.cache[confChild.id]
              if (typeof cachedItem === 'undefined' || cachedItem === null) {
                skus.push(confChild.sku)
              }
            }
            if (skus.length > 0) {
              rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
            }
          }
        }
      }
    },
    incrementEvent (product, event) {
      let value = parseInt(this.$refs['qty' + product.id].value)
      if (isNaN(value)) { value = 0 }
      this.$refs['qty' + product.id].value = value + 1

      for (var i = 0; i < this.$store.state.cart.cartItems.length; i++) {
        let item = this.$store.state.cart.cartItems[i]
        if (item.id == product.id) {
          let quantity = value + 1;
          this.updateQuantity(quantity)
          this.$store.dispatch('cart/sync', { forceClientState: true })
          this.active_el.added_into_cart = true
          this.active_el.show_qty = true
          this.qtyupdating = true
        }
      }
      setTimeout(() => this.qtyupdating = false, 500);
    },
    decrementEvent (product, event) {
      let value = parseInt(this.$refs['qty' + product.id].value)
      if (isNaN(value)) { value = 0 }
      for (var i = 0; i < this.$store.state.cart.cartItems.length; i++) {
        let item = this.$store.state.cart.cartItems[i]
        if (item.id == product.id) {
          this.qtyupdating = true
        }
      }
      this.$refs['qty' + product.id].value = value > 1 ? value - 1 : 1
      if (value > 1) {
        let quantity = value - 1;
        this.updateQuantity(quantity)
        this.$store.dispatch('cart/sync', { forceClientState: true })
      }
      if (value == 1) {
        this.$store.dispatch('cart/removeItem', { product: product })
        this.$store.dispatch('cart/sync', { forceClientState: true })
        this.active_el.added_into_cart = false
        this.active_el.show_qty = false
        this.product.qty = 1;
      }
      setTimeout(() => this.qtyupdating = false, 500);
    },
    quickAddToCart (product, event) {
      let key = 'qty'+product.id
      let value = parseInt(this.$refs[key].value)
      product.qty = value
      this.addToCart(product)
      if(this.flag){
        this.active_el.added_into_cart = true
        this.active_el.show_qty = true
      }
    },
    afterDeleteItem (event) {
      self.product = this.product
      self.active_el = this.active_el
      if (event.items.length > 0) {
        event.items.forEach((item) => {
          if (self.product.id == item.id) {
            self.active_el.added_into_cart = true
            self.active_el.show_qty = true
          } else {
            self.active_el.added_into_cart = false
            self.active_el.show_qty = false
            self.product.qty = 1;
          }
        })
      } else {
        this.active_el.added_into_cart = false
        this.active_el.show_qty = false
        this.product.qty = 1;
      }
    },
    updateCartAndQty (event) {
      if (this.product.length !== 0) {
        if (this.product.id == event.item.id) {
          this.active_el.added_into_cart = true
          this.active_el.show_qty = true
          this.$set(this.product, 'qty', event.item.qty)
          // console.log('seller updated item ',this.product)
        }
      }
    },
    customAfterDeleteItem ($event) {
      if ($event.product.id == this.product.id) {
        this.active_el.added_into_cart = false
        this.active_el.show_qty = false
        this.product.qty = 1;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';

%label {
  @apply text-xs font-medium tracking-sm absolute top-0 left-0 flex justify-center items-center uppercase border border-solid;

  padding: 7px 10px;
  line-height: 8px;
}

.product-image {
  @apply flex bg-grey-lightest;
  overflow: hidden;

  .hover-image {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: visibility $duration-main $motion-main, opacity $duration-main $motion-main;
  }

  &:hover {
    &.sale::after,
    &.new::after {
      opacity: 0.8;
    }

    .hover-image {
      visibility: visible;
      opacity: 1;
    }

    .default-image {
      opacity: 0;
    }
  }

  img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    margin: auto;
    mix-blend-mode: darken;
    transition: opacity $duration-main $motion-main;

    &[lazy="loaded"] {
      animation: products-loaded;
      animation-duration: 0.3s;
    }

    @keyframes products-loaded {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &.sale {
    &::after {
      @extend %label;
      @apply m-1 text-error border-error-lighter bg-error-lightest;
      content: 'Sale';
    }
  }

  &.new {
    &::after {
      @extend %label;
      @apply m-1 text-primary border-primary-lighter bg-primary-lightest;
      content: 'New';
    }
  }
}

.product-link {
  .product-name {
    transition: color $duration-main $motion-main;
  }

  &:hover {
    .product-name {
      @apply text-primary;
    }
  }
}
button.add-quant-btn.added{
    background: #7bed87;
    color: #000;
    visibility: hidden;
}
.quanty-container-new .qty_full_width{
    width: 50%;
    display: flex;
}
.list .product-image.w-full.relative{
  width:50%;
  float:left;
  transition: 1s;
}
.list .seller-product-info{
  float:left;
}
.list .quanty-container-new{
  clear: left;
  width: 33%;
  float: right;
}
.list .product{
  border-bottom: 1px solid #e8e8e8 !important;
}
.grid .product-image.w-full.relative{
  transition: 1s;
}
.page-br{
  display: none;
}
.product-listing-sec{
  .seller-product-info{
    > div span.curr_distance{
      float: right;
      color: #45289f;
      border: 1px solid #45289f;
      padding: 3px;
      border-radius: 5px;
    }
  }
}

.seller-product-info .category-name{
  margin-bottom: 12px;
}
.seller-product-info .category-name{
  font-size: 12px !important;
  color: #979797 !important;
}
.seller-product-listing .product .pro-name-seller{
  font-weight: 600;
  margin-bottom: 0px !important;
  height: 19px !important;
}
.list .product{
  border-width: 0px;
}
.mark-distance {
  font-size: 12px !important;
  font-weight: 600;
  margin-right: 2px;
  float: right;
  color: #45289f;
  border: 1px solid #45289f;
  padding: 3px;
  border-radius: 5px;
}
.recently-slider .seller-product-info > div span {
  font-size: 12px !important;
}
.location-span {
  padding-left: 0px;
}
.location-pro{
  display: block !important;
}

@media (max-width: 767px){
  .quanty-container-new > div .input-group .inc-dec-numb {
    width: 27px;
    height: 27px;
    vertical-align: middle;
    line-height: 27px;
    display: inline-block;
    border: transparent;
    color: #222222;
    padding: 0;
    background: #f1f1f1;
  }
  .quanty-container-new > div .input-group input {
    color : #808080;
    font-weight: 300;
  }  
}
.product-sale{
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  top: 0px !important;
}

</style>
