import { MutationTree } from 'vuex'
import DoyenhubSellerState from '../types/DoyenhubSellerState'

const mutations = {
  sellerInfo (state, data) {
    state.sellerInfo = data
  },
  sellerProducts (state, data) {
    state.sellerProducts = data
  },
  sellerProductsFilter (state, data) {
    state.sellerProductsFilter = data
  },
  sellersFilter (state, data) {
    state.sellersFilter = data
  },
  bestSellersFilter (state, data) {
    state.bestSellersFilter = data
  },
  sellerReviewListing (state, data) {
    state.sellerReviewListing = data
  },
  IsUserAllowForReview (state, data) {
    state.IsUserAllowForReview = data
  },
  breadcrumbs (state, data) {
    state.breadcrumbs = data
  },
  updateSellerProductsLoader (state, data) {
    state.sellerProductsFilterLoad = data
  }
}

export default mutations
