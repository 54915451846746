class SearchQuery {
  /**
    */
  constructor () {
    this._availableFilters = []
    this._appliedFilters = []
    this._searchText = ''
  }

  /**
    * @return {Array} array of all available filters objects
    */
  getAvailableFilters () {
    return this._availableFilters
  }

  /**
    * @return {Array} array of applied filters objects
    */
  getAppliedFilters () {
    return this._appliedFilters
  }

  /**
    * @return {String}
    */
  getSearchText () {
    return this._searchText
  }

  /**
    * @param {Object}
    * @return {Object}
    */
  applyFilter ({key, value, scope = 'default', options = Object}) {
    let flag = false
    for (var filterKey in this._appliedFilters) {
      if (this._appliedFilters[filterKey].attribute === key) {
        if (this._appliedFilters[filterKey].value.in === value.in) {
          flag = true
        }
        if (this._appliedFilters[filterKey].attribute === 'geo_sort') {
          if (typeof value.eq !== 'undefined' && value.eq === '') {
            flag = true
          }
        }
        if (this._appliedFilters[filterKey].attribute === 'price') {
          if (this._appliedFilters[filterKey].value.gte !== value.gte) {
            flag = false
          } else if (this._appliedFilters[filterKey].value.lte !== value.lte) {
            flag = false
          }
        }
        if (this._appliedFilters[filterKey].attribute === 'extension_attributes.rating') {
          if (this._appliedFilters[filterKey].value.gte !== value.gte) {
            flag = false
          }
        }
        if (this._appliedFilters[filterKey].attribute === 'rating') {
          if (this._appliedFilters[filterKey].value.eq !== value.eq) {
            flag = false
          }
        }
        if (this._appliedFilters[filterKey].attribute === 'review') {
          if (this._appliedFilters[filterKey].value.eq !== value.eq) {
            flag = false
          }
        }
        if (this._appliedFilters[filterKey].attribute === 'review') {
          if (this._appliedFilters[filterKey].value.gte !== value.gte) {
            flag = false
          }
        }
        if (this._appliedFilters[filterKey].attribute === 'seller_min_price') {
          if (this._appliedFilters[filterKey].value.gte !== value.gte) {
            flag = false
          } else if (this._appliedFilters[filterKey].value.lte !== value.lte) {
            flag = false
          }
        }
        this._appliedFilters.splice(filterKey, 1);
      } else if (key === 'geo_sort') {
        if (typeof value.eq !== 'undefined' && value.eq === '') {
          flag = true
        }
      }
    }
    if (key === 'dynamic_cat_filter') {
      key = 'category_ids'
    } else if (key === 'dynamic_seller_cat_filter') {
      key = 'cat_ids'
    }
    if (!flag) {
      this._appliedFilters.push({
        attribute: key,
        value: value,
        scope: scope,
        options: options
      })
    }

    return this
  }

  /**
    * @param {Object}
    * @return {Object}
    */
  addAvailableFilter ({field, scope = 'default', options = {}}) {
    // value can has only String, Array or numeric type
    this._availableFilters.push({
      field: field,
      scope: scope,
      options: options
    })

    return this
  }

  /**
  * @param {Array} filters
  * @return {Object}
  */
  setAvailableFilters (filters) {
    this._availableFilters = filters
    return this
  }

  /**
  * @param {String} searchText
  * @return {Object}
  */
  setSearchText (searchText) {
    this._searchText = searchText
    return this
  }
}

export default SearchQuery
