import Vue from 'vue'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { entityKeyName } from '@vue-storefront/core/store/lib/entities'
import rootStore from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import chunk from 'lodash-es/chunk'
import trim from 'lodash-es/trim'
import toString from 'lodash-es/toString'
import { optionLabel } from '../../helpers/optionLabel'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from '../../types/CategoryState'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { currentStoreView, localizedDispatcherRoute } from '@vue-storefront/core/lib/multistore'
import { Logger } from '@vue-storefront/core/lib/logger'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'
import { formatCategoryLink } from 'core/modules/url/helpers'

const actions: ActionTree<CategoryState, RootState> = {
  /**
   * Reset current category and path
   * @param {Object} context
   */
  reset (context) {
    context.commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, [])
    context.commit(types.CATEGORY_UPD_CURRENT_CATEGORY, {})
    rootStore.dispatch('stock/clearCache')
    Vue.prototype.$bus.$emit('category-after-reset', { })
  },
  /**
   * Load categories within specified parent
   * @param {Object} commit promise
   * @param {Object} parent parent category
   */
  list (context, { parent = null, key = null, value = null, level = null, onlyActive = true, onlyNotEmpty = false, size = 4000, start = 0, sort = 'position:asc', includeFields = config.entities.optimize ? config.entities.category.includeFields : null, excludeFields = config.entities.optimize ? config.entities.category.excludeFields : null, skipCache = false, updateState = true }) {
    const commit = context.commit
    let customizedQuery = false // that means the parameteres are != defaults; with defaults parameter the data could be get from window.__INITIAL_STATE__ - this is optimisation trick
    let searchQuery = new SearchQuery()
    if (parent && typeof parent !== 'undefined') {
      searchQuery = searchQuery.applyFilter({key: 'parent_id', value: { 'eq': typeof parent === 'object' ? parent.id : parent }})
      customizedQuery = true
    }
    if (level !== null) {
      searchQuery = searchQuery.applyFilter({key: 'level', value: {'eq': level}})
      if (level !== config.entities.category.categoriesDynamicPrefetchLevel && !isServer) { // if this is the default level we're getting the results from window.__INITIAL_STATE__ not querying the server
        customizedQuery = true
      }
    }

    if (key !== null) {
      if (Array.isArray(value)) {
        searchQuery = searchQuery.applyFilter({key: key, value: {'in': value}})
      } else {
        searchQuery = searchQuery.applyFilter({key: key, value: {'eq': value}})
      }
      customizedQuery = true
    }

    if (onlyActive === true) {
      searchQuery = searchQuery.applyFilter({key: 'is_active', value: {'eq': true}})
    }

    if (onlyNotEmpty === true) {
      searchQuery = searchQuery.applyFilter({key: 'product_count', value: {'gt': 0}})
      customizedQuery = true
    }
    if (skipCache || ((!context.state.list || context.state.list.length === 0) || customizedQuery)) {
      return quickSearchByQuery({ entityType: 'category', query: searchQuery, sort: sort, size: size, start: start, includeFields: includeFields, excludeFields: excludeFields }).then((resp) => {
        for (let category of resp.items) {
          if (category.url_path && updateState) {
            rootStore.dispatch('url/registerMapping', {
              url: localizedDispatcherRoute(category.url_path, currentStoreView().storeCode),
              routeData: {
                params: {
                  'slug': category.slug
                },
                'name': 'category'
              }
            }, { root: true })
          }
        }
        if (updateState) {
          commit(types.CATEGORY_UPD_CATEGORIES, Object.assign(resp, { includeFields, excludeFields }))
          Vue.prototype.$bus.$emit('category-after-list', { query: searchQuery, sort: sort, size: size, start: start, list: resp })
        }
        return resp
      })
    } else {
      return new Promise((resolve, reject) => {
        let resp = { items: context.state.list, total: context.state.list.length }
        if (updateState) {
          Vue.prototype.$bus.$emit('category-after-list', { query: searchQuery, sort: sort, size: size, start: start, list: resp })
        }
        resolve(resp)
      })
    }
  },

  getAllCategories (context, {key, value}) {
    const state = context.state
    const commit = context.commit
    const dispatch = context.dispatch
    return new Promise((resolve, reject) => {
      context.dispatch('list', { key: key, value: value }).then(res => {
        context.commit(types.CATEGORY_ALL, res.items)
      })
    })
  },

  /**
   * Load category object by specific field - using local storage/indexed Db
   * loadCategories() should be called at first!
   * @param {Object} commit
   * @param {String} key
   * @param {String} value
   * @param {Bool} setCurrentCategory default=true and means that state.current_category is set to the one loaded
   */
  single (context, { key, value, setCurrentCategory = true, setCurrentCategoryPath = true, populateRequestCacheTags = true, skipCache = false }) {
    const state = context.state
    const commit = context.commit
    const dispatch = context.dispatch

    return new Promise((resolve, reject) => {
      const fetchCat = ({ key, value }) => {
        if (key !== 'id' || value >= config.entities.category.categoriesRootCategorylId/* root category */) {
          context.dispatch('list', { key: key, value: value }).then(res => {
            if (res && res.items && res.items.length) {
              setcat(null, res.items[0]) // eslint-disable-line @typescript-eslint/no-use-before-define
            } else {
              reject(new Error('Category query returned empty result ' + key + ' = ' + value))
            }
          }).catch(reject)
        } else {
          reject(new Error('Category query returned empty result ' + key + ' = ' + value))
        }
      }
      let setcat = (error, mainCategory) => {
        if (!mainCategory) {
          fetchCat({ key, value })
          return
        }
        if (error) {
          Logger.error(error)()
          reject(error)
        }

        if (setCurrentCategory) {
          commit(types.CATEGORY_UPD_CURRENT_CATEGORY, mainCategory)
        }
        if (populateRequestCacheTags && mainCategory && Vue.prototype.$ssrRequestContext) {
          Vue.prototype.$ssrRequestContext.output.cacheTags.add(`C${mainCategory.id}`)
        }
        if (setCurrentCategoryPath) {
          let currentPath = []
          let recurCatFinder = (category) => {
            if (!category) {
              return
            }
            if (category.parent_id >= config.entities.category.categoriesRootCategorylId) {
              dispatch('single', { key: 'id', value: category.parent_id, setCurrentCategory: false, setCurrentCategoryPath: false }).then((sc) => { // TODO: move it to the server side for one requests OR cache in indexedDb
                if (!sc) {
                  commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath)
                  Vue.prototype.$bus.$emit('category-after-single', { category: mainCategory })
                  return resolve(mainCategory)
                }
                currentPath.unshift(sc)
                if (sc.parent_id) {
                  recurCatFinder(sc)
                }
              }).catch(err => {
                Logger.error(err)()
                commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath) // this is the case when category is not binded to the root tree - for example 'Erin Recommends'
                resolve(mainCategory)
              })
            } else {
              commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath)
              Vue.prototype.$bus.$emit('category-after-single', { category: mainCategory })
              resolve(mainCategory)
            }
          }
          if (typeof mainCategory !== 'undefined') {
            recurCatFinder(mainCategory) // TODO: Store breadcrumbs in IndexedDb for further usage to optimize speed?
          } else {
            reject(new Error('Category query returned empty result ' + key + ' = ' + value))
          }
        } else {
          Vue.prototype.$bus.$emit('category-after-single', { category: mainCategory })
          resolve(mainCategory)
        }
      }

      let foundInLocalCache = false
      if (state.list.length > 0 && !skipCache) { // SSR - there were some issues with using localForage, so it's the reason to use local state instead, when possible
        let category = state.list.find((itm) => { return itm[key] === value })
        // Check if category exists in the store OR we have recursively reached Default category (id=1)
        if (category && value >= config.entities.category.categoriesRootCategorylId/** root category parent */) {
          foundInLocalCache = true
          setcat(null, category)
        }
      }
      if (!foundInLocalCache) {
        if (skipCache || isServer) {
          fetchCat({ key, value })
        } else {
          const catCollection = Vue.prototype.$db.categoriesCollection
          // Check if category does not exist in the store AND we haven't recursively reached Default category (id=1)
          catCollection.getItem(entityKeyName(key, value), setcat)
        }
      }
    })
  },
  /**
   * Filter category products
   */
  products (context, { populateAggregations = false, filters = [], searchProductQuery, current = 0, perPage = 50, sort = '', includeFields = null, excludeFields = null, configuration = null, append = false, skipCache = false, cacheOnly = false }) {
    context.dispatch('setSearchOptions', {
      populateAggregations,
      filters,
      current,
      perPage,
      includeFields,
      excludeFields,
      configuration,
      append,
      sort
    })

    let prefetchGroupProducts = true
    if (config.entities.twoStageCaching && config.entities.optimize && !isServer && !rootStore.state.twoStageCachingDisabled) { // only client side, only when two stage caching enabled
      includeFields = config.entities.productListWithChildren.includeFields // we need configurable_children for filters to work
      excludeFields = config.entities.productListWithChildren.excludeFields
      prefetchGroupProducts = false
      Logger.log('Using two stage caching for performance optimization - executing first stage product pre-fetching')()
    } else {
      prefetchGroupProducts = true
      if (rootStore.state.twoStageCachingDisabled) {
        Logger.log('Two stage caching is disabled runtime because of no performance gain')()
      } else {
        Logger.log('Two stage caching is disabled by the config')()
      }
    }
    if (cacheOnly) {
      excludeFields = null
      includeFields = null
      Logger.log('Caching request only, no state update')()
    }
    let t0 = new Date().getTime()
    
    const precachedQuery = searchProductQuery
    let productPromise = rootStore.dispatch('product/list', {
      query: precachedQuery,
      start: current,
      size: perPage,
      excludeFields: excludeFields,
      includeFields: includeFields,
      configuration: configuration,
      append: append,
      sort: sort,
      updateState: !cacheOnly,
      prefetchGroupProducts: prefetchGroupProducts
    }).then((res) => {
      let t1 = new Date().getTime()
      rootStore.state.twoStageCachingDelta1 = t1 - t0

      let subloaders = []
      if (!res || (res.noresults)) {
        /* rootStore.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t('No products synchronized for this category. Please come back while online!'),
          action1: { label: i18n.t('OK') }
        }) */
        if (!append) rootStore.dispatch('product/reset')
        rootStore.state.product.list = { items: [] } // no products to show TODO: refactor to rootStore.state.category.reset() and rootStore.state.product.reset()
        // rootStore.state.category.filters = { color: [], size: [], price: [] }
        return []
      } else {
        if (config.products.filterUnavailableVariants && config.products.configurableChildrenStockPrefetchStatic) { // prefetch the stock items
          const skus = []
          let prefetchIndex = 0
          res.items.map(i => {
            if (config.products.configurableChildrenStockPrefetchStaticPrefetchCount > 0) {
              if (prefetchIndex > config.products.configurableChildrenStockPrefetchStaticPrefetchCount) return
            }
            skus.push(i.sku) // main product sku to be checked anyway
            if (i.type_id === 'configurable' && i.configurable_children && i.configurable_children.length > 0) {
              for (const confChild of i.configurable_children) {
                const cachedItem = context.rootState.stock.cache[confChild.id]
                if (typeof cachedItem === 'undefined' || cachedItem === null) {
                  skus.push(confChild.sku)
                }
              }
              prefetchIndex++
            }
          })
          for (const chunkItem of chunk(skus, 15)) {
            rootStore.dispatch('stock/list', { skus: chunkItem, skipCache }) // store it in the cache
          }
        }
        if (populateAggregations === true && res.aggregations) { // populate filter aggregates
          for (let attrToFilter of filters) { // fill out the filter options
            let filterOptions = []

            let uniqueFilterValues = new Set<string>()
            if (attrToFilter !== 'price') {
              if (res.aggregations['agg_terms_' + attrToFilter]) {
                let buckets = res.aggregations['agg_terms_' + attrToFilter].buckets
                if (res.aggregations['agg_terms_' + attrToFilter + '_options']) {
                  buckets = buckets.concat(res.aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
                }

                for (let option of buckets) {
                  uniqueFilterValues.add(toString(option.key))
                }
              }

              uniqueFilterValues.forEach(key => {
                let doc_count = 0
                let obj_doc_count = res.aggregations['agg_terms_' + attrToFilter].buckets.filter(obj =>{
                  return obj.key == key;
                })
                if (typeof obj_doc_count[0] !== 'undefined') {
                  doc_count = obj_doc_count[0].doc_count
                }
                const label = optionLabel(rootStore.state.attribute, { attributeKey: attrToFilter, optionId: key })
                if (trim(label) !== '') { // is there any situation when label could be empty and we should still support it?
                  filterOptions.push({
                    id: key,
                    label: label,
                    dcount: doc_count
                  })
                }
              });
            } else { // special case is range filter for prices
              const storeView = currentStoreView()
              const currencySign = storeView.i18n.currencySign
              if (res.aggregations['agg_range_' + attrToFilter]) {
                let index = 0
                let count = res.aggregations['agg_range_' + attrToFilter].buckets.length
                for (let option of res.aggregations['agg_range_' + attrToFilter].buckets) {
                  filterOptions.push({
                    dcount: option.doc_count, 
                    id: option.key,
                    from: option.from,
                    to: option.to,
                    label: (index === 0 || (index === count - 1)) ? (option.to ? '< ' + currencySign + option.to : '> ' + currencySign + option.from) : currencySign + option.from + (option.to ? ' - ' + option.to : '')// TODO: add better way for formatting, extract currency sign
                  })
                  index++
                }
              }
            }
            context.dispatch('addAvailableFilter', {
              key: attrToFilter,
              options: filterOptions
            })
          }
        }
      }
      return subloaders
    }).catch((err) => {
      Logger.error(err)()
      /* rootStore.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('No products synchronized for this category. Please come back while online!'),
        action1: { label: i18n.t('OK') }
      }) */
    })

    if (config.entities.twoStageCaching && config.entities.optimize && !isServer && !rootStore.state.twoStageCachingDisabled && !cacheOnly) { // second stage - request for caching entities; if cacheOnly set - the caching took place with the stage1 request!
      Logger.log('Using two stage caching for performance optimization - executing second stage product caching', 'category') // TODO: in this case we can pre-fetch products in advance getting more products than set by pageSize()
      rootStore.dispatch('product/list', {
        query: precachedQuery,
        start: current,
        size: perPage,
        excludeFields: null,
        includeFields: null,
        configuration: configuration,
        sort: sort,
        updateState: false, // not update the product listing - this request is only for caching
        prefetchGroupProducts: prefetchGroupProducts
      }).catch((err) => {
        Logger.info("Problem with second stage caching - couldn't store the data", 'category')()
        Logger.info(err, 'category')()
      }).then((res) => {
        let t2 = new Date().getTime()
        rootStore.state.twoStageCachingDelta2 = t2 - t0
        Logger.log('Using two stage caching for performance optimization - Time comparison stage1 vs stage2' + rootStore.state.twoStageCachingDelta1 + rootStore.state.twoStageCachingDelta2, 'category')()
        if (rootStore.state.twoStageCachingDelta1 > rootStore.state.twoStageCachingDelta2) { // two stage caching is not making any good
          rootStore.state.twoStageCachingDisabled = true
          Logger.log('Disabling two stage caching', 'category')()
        }
      })
    }
    return productPromise
  },
  addAvailableFilter ({commit}, {key, options} = {}) {
    if (key) commit(types.CATEGORY_ADD_AVAILABLE_FILTER, {key, options})
  },
  resetFilters (context) {
    context.commit(types.CATEGORY_REMOVE_FILTERS)
  },
  searchProductQuery (context, productQuery) {
    context.commit(types.CATEGORY_UPD_SEARCH_PRODUCT_QUERY, productQuery)
  },
  setSearchOptions ({commit}, searchOptions) {
    commit(types.CATEGORY_SET_SEARCH_OPTIONS, searchOptions)
  },
  mergeSearchOptions ({commit}, searchOptions) {
    commit(types.CATEGORY_MERGE_SEARCH_OPTIONS, searchOptions)
  },
  // allProducts (context, { populateAggregations = false, filters = [], searchProductQuery, current = 0, perPage = 50, sort = '', includeFields = null, excludeFields = null, configuration = null, append = false, skipCache = false, cacheOnly = false, minimum_distance = 0 }) {
  async allProducts (context, { populateAggregations = false, filters = [], searchProductQuery, current = 0, perPage = 50, sort = '', includeFields = null, excludeFields = null, configuration = null, append = false, skipCache = false, cacheOnly = false}) {
    // context.dispatch('setSearchOptions', {
    //   populateAggregations,
    //   filters,
    //   current,
    //   perPage,
    //   includeFields,
    //   excludeFields,
    //   configuration,
    //   append,
    //   sort
    // })

    let prefetchGroupProducts = true
    if (config.entities.twoStageCaching && config.entities.optimize && !isServer && !rootStore.state.twoStageCachingDisabled) { // only client side, only when two stage caching enabled
      includeFields = config.entities.productListWithChildren.includeFields // we need configurable_children for filters to work
      excludeFields = config.entities.productListWithChildren.excludeFields
      prefetchGroupProducts = false
      Logger.log('Using two stage caching for performance optimization - executing first stage product pre-fetching')()
    } else {
      prefetchGroupProducts = true
      if (rootStore.state.twoStageCachingDisabled) {
        Logger.log('Two stage caching is disabled runtime because of no performance gain')()
      } else {
        Logger.log('Two stage caching is disabled by the config')()
      }
    }
    if (cacheOnly) {
      excludeFields = null
      includeFields = null
      Logger.log('Caching request only, no state update')()
    }
    let t0 = new Date().getTime()
    const dispatch = context.dispatch
    const precachedQuery = searchProductQuery
    let productPromise = rootStore.dispatch('product/list', {
      query: precachedQuery,
      start: current,
      size: 6000,
      excludeFields: excludeFields,
      includeFields: includeFields,
      configuration: configuration,
      append: append,
      sort: sort,
      updateState: !cacheOnly,
      prefetchGroupProducts: prefetchGroupProducts
    }).then((res) => {
      let t1 = new Date().getTime()
      rootStore.state.twoStageCachingDelta1 = t1 - t0
      context.dispatch('setSearchOptions', {
        populateAggregations,
        filters,
        current,
        perPage,
        includeFields,
        excludeFields,
        configuration,
        append,
        sort
      })
      let subloaders = []
      if (!res || (res.noresults)) {
        /* rootStore.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t('No products synchronized for this category. Please come back while online!'),
          action1: { label: i18n.t('OK') }
        }) */
        if (!append) rootStore.dispatch('product/reset')
        rootStore.state.product.list = { items: [] } // no products to show TODO: refactor to rootStore.state.category.reset() and rootStore.state.product.reset()
        // rootStore.state.category.filters = { color: [], size: [], price: [] }
        return []
      } else {
        if (config.products.filterUnavailableVariants && config.products.configurableChildrenStockPrefetchStatic) { // prefetch the stock items
          const skus = []
          let prefetchIndex = 0
          res.items.map(i => {
            if (config.products.configurableChildrenStockPrefetchStaticPrefetchCount > 0) {
              if (prefetchIndex > config.products.configurableChildrenStockPrefetchStaticPrefetchCount) return
            }
            skus.push(i.sku) // main product sku to be checked anyway
            if (i.type_id === 'configurable' && i.configurable_children && i.configurable_children.length > 0) {
              for (const confChild of i.configurable_children) {
                const cachedItem = context.rootState.stock.cache[confChild.id]
                if (typeof cachedItem === 'undefined' || cachedItem === null) {
                  skus.push(confChild.sku)
                }
              }
              prefetchIndex++
            }
          })
          // for (const chunkItem of chunk(skus, 15)) {
          //   rootStore.dispatch('stock/list', { skus: chunkItem, skipCache }) // store it in the cache
          // }
        }
        //GET ALL PRODUCT IN MINIMUM KM
        let currlatitude = rootStore.state.user.current_user_latitude
        let currlongitude = rootStore.state.user.current_user_longitude
        let productData = res.items
        var response = {}
        let pro_price = []
        let all_products = new Array()
        let total_min_km_pro = 0
        let price_interval = 500
        var category_filter = {}
        var vars = {};
        
        response['color_filter'] = new Array()
        response['size_filter'] = new Array()
        let counter_var = 0
        let color_cnt_var = 0
        let size_cnt_var = 0
        let categories_ids = []
        let color_ids = []
        let size_ids = []
        let pro_attributes = []
        pro_attributes['color'] = []
        pro_attributes['size'] = []
        let tmp = {}
        //TODO::GET ALL ATTRIBUTES
        context.dispatch('attribute/list', {filterValues: [93], filterField: 'attribute_id'}, { root: true })
        let attributes = context.rootGetters['attribute/attributeListByCode']
        if (typeof attributes['color'] !== 'undefined' && typeof attributes['color']['options'] !== 'undefined') {
          if (attributes['color']['options'].length > 0) {
            for (var att_cn = 0; att_cn < attributes['color']['options'].length; att_cn++) {
              if (attributes['color']['options'][att_cn]['label'] !== '' &&  attributes['color']['options'][att_cn]['value'] != '') {
                // console.log(attributes['color']['options'][att_cn]['label'])
                pro_attributes['color'][attributes['color']['options'][att_cn]['value']] = attributes['color']['options'][att_cn]['label']
              }
            }
          }
        }
        if (typeof attributes['size'] !== 'undefined' && typeof attributes['size']['options'] !== 'undefined') {
          if (attributes['size']['options'].length > 0) {
            for (var att_cn = 0; att_cn < attributes['size']['options'].length; att_cn++) {
              if (attributes['size']['options'][att_cn]['label'] !== '' && attributes['size']['options'][att_cn]['value'] !== '') {
                // console.log(attributes['size']['options'][att_cn]['label'])
                pro_attributes['size'][attributes['size']['options'][att_cn]['value']] = attributes['size']['options'][att_cn]['label']
              }
            }
          }
        }
        let pro_key = 0
        for (var i = 0; i < Object.keys(productData).length; i++) {
          let product = productData[i]
          if (typeof product['extension_attributes'] !== 'undefined' && typeof product['extension_attributes']['seller_id'] !== 'undefined' && typeof product['extension_attributes']['seller_id']['0']['0'] !== 'undefined') {
            if (typeof currlatitude !== 'undefined' && currlatitude !== '' && typeof currlongitude !== 'undefined' && currlongitude !== '') {
              if (product['curr_distance']) {
                let distanceKm = product['curr_distance']
                if (distanceKm <= rootStore.state.config.seller.maxKmsRange) {
                  all_products[pro_key] = new Array()
                  all_products[pro_key]['id'] = product['id']
                  all_products[pro_key]['distanceKm'] = distanceKm
                  all_products[pro_key]['category'] = product['category']

                  total_min_km_pro++
                  if(product['special_price'] && parseFloat(product['originalPriceInclTax']) > 0){
                    all_products[pro_key]['price'] = product['originalPriceInclTax']
                    pro_price.push(product['originalPriceInclTax'])
                  }else{
                    all_products[pro_key]['price'] = product['priceInclTax']
                    pro_price.push(product['priceInclTax'])
                  }

                  //PREPARE CATEGORY FILTER
                  if (typeof product['category'] != 'undefined' && product['category'].length > 0) {
                    if (typeof product['category_ids'] != 'undefined' && product['category_ids'].length > 0) {
                      product['category_ids'] = product['category_ids'].map(function (x) { 
                        return parseInt(x, 10); 
                      });
                      all_products[pro_key]['category_ids'] = product['category_ids']
                    }
                    for (var j = 0; j < product['category'].length; j++) {
                      if (typeof vars['dcount_' + product['category'][j].category_id ] == 'undefined') {
                        vars['dcount_' + product['category'][j].category_id ] = 1
                        if (typeof product['category'][j].slug != 'undefined') {
                          categories_ids[product['category'][j].slug] = counter_var
                          counter_var++
                        }
                      }
                      if (typeof categories_ids[product['category'][j].slug] != 'undefined') {
                        let obj = {'id': product['category'][j].category_id, 'dcount' : vars['dcount_' + product['category'][j].category_id]++, 'label': product['category'][j].name} 
                        tmp[categories_ids[product['category'][j].slug]] = obj
                        // response['category_filter'].push(obj)
                      }
                    }
                  }
                  //PREPARE CATEGORY FILTER

                  //PREPARE COLOR FILTER
                  all_products[pro_key]['type_id'] = product['type_id']
                  if (typeof product['color_options'] !== 'undefined' && product['type_id'] == 'configurable') {
                    // console.log('all_products ', all_products[i])
                    all_products[pro_key]['color_options'] = product['color_options']
                    //AVAILABLE MULTIPLE COLORS
                    for (var cl = 0; cl < product['color_options'].length; cl++) {
                      let color_key = 'color_'+product['color_options'][cl]
                      if (typeof vars['dcount_' + product['color_options'][cl] ] == 'undefined') {
                        vars['dcount_' + product['color_options'][cl]] = 1
                        color_ids[color_key] = color_cnt_var
                        color_cnt_var++
                      }
                      if (typeof color_ids[color_key] != 'undefined') {
                        // console.log('TETET ', color_ids)
                        response['color_filter'][color_ids[color_key]] = {'id': product['color_options'][cl], 'dcount' : vars['dcount_' + product['color_options'][cl]]++, 'label': pro_attributes['color'][product['color_options'][cl]]} 
                      }
                    }
                  } else if (typeof product['color'] !== 'undefined') {
                    all_products[pro_key]['color_options'] = []
                    all_products[pro_key]['color_options'].push(parseInt(product['color']))
                    //AVAILABLE SINGLE COLORS
                    let color_key = 'color_'+product['color']
                    if (typeof vars['dcount_' + product['color'] ] == 'undefined') {
                      vars['dcount_' + product['color']] = 1
                      color_ids[color_key] = color_cnt_var
                      color_cnt_var++
                    }
                    if (typeof color_ids[color_key] != 'undefined') {
                      response['color_filter'][color_ids[color_key]] = {'id': product['color'], 'dcount' : vars['dcount_' + product['color']]++, 'label': pro_attributes['color'][product['color']]} 
                    }
                  }
                  //PREPARE COLOR FILTER

                  //PREPARE SIZE FILTER
                  if (typeof product['size_options'] !== 'undefined' && product['type_id'] == 'configurable') {
                    all_products[pro_key]['size_options'] = product['size_options']
                    //AVAILABLE MULTIPLE SIZE
                    for (var cl = 0; cl < product['size_options'].length; cl++) {
                      let size_key = 'size_'+product['size_options'][cl]
                      if (typeof vars['dcount_' + product['size_options'][cl] ] == 'undefined') {
                        vars['dcount_' + product['size_options'][cl]] = 1
                        size_ids[size_key] = size_cnt_var
                        size_cnt_var++
                      }
                      if (typeof size_ids[size_key] != 'undefined') {
                        response['size_filter'][size_ids[size_key]] = {'id': product['size_options'][cl], 'dcount' : vars['dcount_' + product['size_options'][cl]]++, 'label': pro_attributes['size'][product['size_options'][cl]]} 
                      }
                    }
                  } else if (typeof product['size'] !== 'undefined') {
                    all_products[pro_key]['size_options'] = []
                    all_products[pro_key]['size_options'].push(parseInt(product['size']))
                    //AVAILABLE SINGLE SIZE
                    let size_key = 'size_'+product['size']
                    if (typeof vars['dcount_' + product['size'] ] == 'undefined') {
                      vars['dcount_' + product['size']] = 1
                      size_ids[size_key] = size_cnt_var
                      size_cnt_var++
                    }
                    if (typeof size_ids[size_key] != 'undefined') {
                      response['size_filter'][size_ids[size_key]] = {'id': product['size'], 'dcount' : vars['dcount_' + product['size']]++, 'label': pro_attributes['size'][product['size']]} 
                    }
                  }
                  //PREPARE SIZE FILTER

                  //ADD RATING DATA
                  if (typeof product['extension_attributes']['rating'] !== 'undefined') {
                    all_products[pro_key]['rating'] = product['extension_attributes']['rating']
                  }
                  //ADD RATING DATA
                  pro_key++
                } 
              }
            }
          }
        }
        let test = []
        response['category_filter'] = new Array()
        for (const [key, val] of Object.entries(tmp)) {
          test.push(val)
        }
        //TODO::STORE ALL PRODUCTS WITH KM, NO PAGINATION
        response['category_filter'] = test
        context.commit(types.ALL_PRODUCTS, all_products)
        if(pro_price.length > 0){
          // pro_price = [...new Set(pro_price)]
          let min_price = Math.min.apply(Math, pro_price)
          let max_price = Math.max.apply(Math, pro_price)
          var priceFilters = {}
          var first_label = 0
          var one_dcount = 0, two_dcount = 0, three_dcount = 0, four_dcount = 0, five_dcount = 0
          //CALCULATE PRICE INTERVAL
          price_interval = Math.round(max_price - min_price)
          price_interval = Math.round(price_interval / 5)
          if(price_interval.toString().length > 1){
            // let lastNum = price_interval / 10
            var toText = price_interval.toString();
            var lastChar = toText.slice(-1);
            var lastNum = +(lastChar);
            price_interval = price_interval - lastNum
          }
          first_label = min_price + price_interval
          let sec_label = first_label + price_interval
          let third_label = sec_label + price_interval
          let fourth_label = third_label + price_interval
          for (var i = 0; i < pro_price.length; i++) {
            let price = parseFloat(pro_price[i])

            if(price >= 0 && price < first_label){
              one_dcount++
            }
            if(price >= first_label && price < sec_label){
              two_dcount++
            }
            if(price >= sec_label && price < third_label){
              three_dcount++
            }
            if(price >= third_label && price < fourth_label){
              four_dcount++
            }
            if(price >= fourth_label){
              five_dcount++
            }
          }
          response['price_filter'] = []
          let priceAttr = {'dcount':one_dcount, 'from':0, 'to':first_label , 'id':1, 'label':'< ₹'+first_label }
          response['price_filter'][0] = priceAttr
          priceAttr = {'dcount':two_dcount, 'from':first_label, 'to':sec_label , 'id':2, 'label':"₹"+first_label+" - ₹"+sec_label }
          response['price_filter'][1] = priceAttr
          priceAttr = {'dcount':three_dcount, 'from':sec_label, 'to':third_label , 'id':3, 'label':"₹"+sec_label+" - ₹"+third_label }
          response['price_filter'][2] = priceAttr
          priceAttr = {'dcount':four_dcount, 'from':third_label, 'to':fourth_label , 'id':4, 'label':"₹"+third_label+" - ₹"+fourth_label }
          response['price_filter'][3] = priceAttr
          priceAttr = {'dcount':five_dcount, 'from':fourth_label, 'to':0 , 'id':5, 'label':"> ₹"+fourth_label }
          response['price_filter'][4] = priceAttr
        }
        response['total_min_km_pro'] = total_min_km_pro
        return response
      }
    }).catch((err) => {
      Logger.error(err)()
    })
    return productPromise
  }
}

export default actions
