import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import LookingForState from '../types/LookingForState'

const getters: GetterTree<LookingForState, RootState> = {
  getLookingFor: state => {
    return state.categories
  },
  getSellerList: state => {
    return state.sellerList
  },
  getBestSeller: state => {
    return state.bestSeller
  },
  getSellerDetail: state => {
    return state.sellerDetail
  },
  getSellerBestPrice: state => {
    return state.sellerBestPrice
  },
  getProductByRatting: state => {
    return state.productByRatting
  },
  writeProductReview: state => {
    return state.writeProductReview
  },
  getReviewListing: state => {
    return state.reviewListing
  },
  getBestDeal: state => {
    return state.bestDeal
  },
  getAllShopFilters: state => state.filters,
}

export default getters
