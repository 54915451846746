var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-grey-lightest" }, [
    _c(
      "div",
      {
        staticClass:
          "container text-xs text-grey leading-normal truncate py-4 breadcrumbs",
      },
      [
        _vm._l(_vm.routes, function (link) {
          return _c(
            "span",
            { key: link.route_link },
            [
              _c(
                "router-link",
                {
                  staticClass: "text-grey",
                  attrs: { to: _vm.localizedRoute(link.route_link) },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("htmlDecode")(link.name)) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "px-2" }, [_vm._v("›")]),
            ],
            1
          )
        }),
        _vm._v(" "),
        _c("span", { staticClass: "text-grey-dark" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("htmlDecode")(_vm.activeRoute)) +
              "\n    "
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }