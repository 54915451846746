var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-listing row m0 center-xs start-md" },
    _vm._l(_vm.products, function (product, key) {
      return _c(
        "div",
        {
          key: product.id,
          staticClass: "col-sm-6 flex",
          class: [
            "col-md-" + ((12 / _vm.columns) % 10),
            _vm.wide(product.sale, product.new, key),
          ],
        },
        [_c("product-tile", { attrs: { product: product } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }