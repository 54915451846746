import Vue from 'vue'
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import DoyenhubSellerState from '../types/DoyenhubSellerState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { processURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import fetch from 'isomorphic-fetch'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import i18n from '@vue-storefront/i18n'
import rootStore from '@vue-storefront/core/store'
import { ReviewRequest } from '@vue-storefront/core/modules/review/types/ReviewRequest'
import Review from '@vue-storefront/core/modules/review/types/Review'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'

const actions = {
  getSellerInfo (context, {id}) {
    let searchQuery = new SearchQuery();

    if (id) {
      // console.log('id', id)
      searchQuery = searchQuery.applyFilter({key: 'seller_id', value: {'eq': id}})
      return quickSearchByQuery(
        {entityType: 'sellerlist', query: searchQuery, size: 1})
        .then((data) => {
          // console.log('data', data.items)
          if (data.items.length !== 0) {
            context.commit(`sellerInfo`, data.items)
          }
        })
    }

    /* let url = (config.seller.endpoint)+'/sellerInfo/{{id}}'.replace('{{id}}', id)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellerInfo`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      }) */
  },
  getSellerInfoWithLocation (context, {id, query}) {
    let searchQuery = new SearchQuery();

    if (id) {
      // console.log('id', id)
      // searchQuery = searchQuery.applyFilter({key: 'seller_id', value: {'eq': id}})
      return quickSearchByQuery(
        {entityType: 'sellerlist', query: query, size: 1})
        .then((data) => {
          if (data.items.length !== 0) {
            context.commit(`sellerInfo`, data.items)
          }
        })
    }
  },
  getSellerProducts (context, {id}) {
    // console.log('id', id)
    /* let searchQuery = new SearchQuery();
    let query = '';

    if(id != 0)
    {
      searchQuery = searchQuery.applyFilter({key: 'seller_id', value: {'eq': id}})
      return quickSearchByQuery(
        { entityType: 'sellerproducts', query: searchQuery})
      .then((data) => {
         if (data.items.length !== 0) {
            // console.log(data.items)
            context.commit(`sellerProducts`, data.items)
         }
      })
    } */

    let url = (config.seller.endpoint) + '/sellerproducts/{{id}}'.replace('{{id}}', id)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellerProducts`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },
  getLocationSellerProducts (context, {id, searchQuery, sort, from = 0, size = 50}) {
    context.commit('updateSellerProductsLoader', true)
    if(id != 0)
    {
      context.dispatch('attribute/list', {filterValues: [93, 146], filterField: 'attribute_id'}, { root: true })
      return quickSearchByQuery(
        { entityType: 'sellerproducts', query: searchQuery, sort: sort, size: size, start: from })
      .then((data) => {
        context.commit('updateSellerProductsLoader', false)
        let res = []
        if (typeof context.state.sellerProductsFilter !== 'undefined' && context.state.sellerProductsFilter.length > 0) {
          res[0] = {
            items: data.items
          }
          res[1] = context.state.sellerProductsFilter[1]
          res[2] = {
            total: data.total
          }
        } else {
          res[0] = {
            items: data.items
          }
          res[1] = {
            availablefilter: {
              price: [],
              category: [],
              color: [],
              size: []
            },
            chosen: {}
          }
          res[2] = {
            total: data.total
          }
        }
        context.commit(`sellerProductsFilter`, res)
      })
      .catch((err) => {
        context.commit('updateSellerProductsLoader', false)
        Logger.log(err)()
        Logger.error(err)()
      })
    } 
  },
  getSellerProductsFilter (context, {id, sort, query, page, limit, flag}) {
    context.commit('updateSellerProductsLoader', true)
    let url = (config.seller.endpoint) + '/sellerProductsFilter/{{id}}/{{sort}}/{{query}}/{{page}}/{{limit}}/{{flag}}'.replace('{{id}}', id).replace('{{sort}}', sort).replace('{{query}}', query).replace('{{page}}', page).replace('{{limit}}', limit).replace('{{flag}}', flag)

    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        context.commit('updateSellerProductsLoader', false)
        if (data.code === 200) {
          // context.commit(`sellerProductsFilter`, data.result)
        }
      })
      .catch((err) => {
        context.commit('updateSellerProductsLoader', false)
        Logger.log(err)()
        Logger.error(err)()
      })
  },
  async allProducts (context, {id, searchQuery}) {
    if(id != 0)
    {
      return quickSearchByQuery(
        { entityType: 'sellerproducts', size: 6000, query: searchQuery})
      .then((res) => {
          var response = {}
          response['color_filter'] = new Array()
          response['size_filter'] = new Array()
          response['size_filter'] = new Array()
          let total_min_km_pro = 0
          response['total_min_km_pro'] = total_min_km_pro
          if (res.items.length !== 0) {
            //GET ALL PRODUCT IN MINIMUM KM
            let currlatitude = rootStore.state.user.current_user_latitude
            let currlongitude = rootStore.state.user.current_user_longitude
            let productData = res.items
            
            let pro_price = []
            let all_products = new Array()
            let price_interval = 500
            var category_filter = {}
            var vars = {};
            let counter_var = 0
            let color_cnt_var = 0
            let size_cnt_var = 0
            let categories_ids = []
            let color_ids = []
            let size_ids = []
            let pro_attributes = []
            pro_attributes['color'] = []
            pro_attributes['size'] = []
            let tmp = {}
            //TODO::GET ALL ATTRIBUTES
            let attributes = context.rootGetters['attribute/attributeListByCode']
            if (typeof attributes['color'] !== 'undefined' && typeof attributes['color']['options'] !== 'undefined') {
              if (attributes['color']['options'].length > 0) {
                for (var att_cn = 0; att_cn < attributes['color']['options'].length; att_cn++) {
                  if (attributes['color']['options'][att_cn]['label'] !== '' &&  attributes['color']['options'][att_cn]['value'] != '') {
                    pro_attributes['color'][attributes['color']['options'][att_cn]['value']] = attributes['color']['options'][att_cn]['label']
                  }
                }
              }
            }
            if (typeof attributes['size'] !== 'undefined' && typeof attributes['size']['options'] !== 'undefined') {
              if (attributes['size']['options'].length > 0) {
                for (var att_cn = 0; att_cn < attributes['size']['options'].length; att_cn++) {
                  if (attributes['size']['options'][att_cn]['label'] !== '' && attributes['size']['options'][att_cn]['value'] !== '') {
                    // console.log(attributes['size']['options'][att_cn]['label'])
                    pro_attributes['size'][attributes['size']['options'][att_cn]['value']] = attributes['size']['options'][att_cn]['label']
                  }
                }
              }
            }
            let pro_key = 0
            for (var i = 0; i < Object.keys(productData).length; i++) {
              let product = productData[i]
              if (typeof product['extension_attributes'] !== 'undefined' && typeof product['extension_attributes']['seller_id'] !== 'undefined' && typeof product['extension_attributes']['seller_id']['0']['0'] !== 'undefined') {
                if (typeof currlatitude !== 'undefined' && currlatitude !== '' && typeof currlongitude !== 'undefined' && currlongitude !== '') {
                  if (product['curr_distance']) {
                    let distanceKm = product['curr_distance']
                    if (distanceKm <= rootStore.state.config.seller.maxKmsRange) {
                      all_products[pro_key] = new Array()
                      all_products[pro_key]['id'] = product['id']
                      all_products[pro_key]['distanceKm'] = distanceKm
                      all_products[pro_key]['category'] = product['category']

                      total_min_km_pro++
                      if(product['special_price'] && parseFloat(product['originalPriceInclTax']) > 0){
                        all_products[pro_key]['price'] = product['originalPriceInclTax']
                        pro_price.push(product['originalPriceInclTax'])
                      }else{
                        all_products[pro_key]['price'] = product['priceInclTax']
                        pro_price.push(product['priceInclTax'])
                      }

                      //PREPARE CATEGORY FILTER
                      if (typeof product['category'] != 'undefined' && product['category'].length > 0) {
                        if (typeof product['category_ids'] != 'undefined' && product['category_ids'].length > 0) {
                          product['category_ids'] = product['category_ids'].map(function (x) { 
                            return parseInt(x, 10); 
                          });
                          all_products[pro_key]['category_ids'] = product['category_ids']
                        }
                        for (var j = 0; j < product['category'].length; j++) {
                          if (typeof vars['dcount_' + product['category'][j].category_id ] == 'undefined') {
                            vars['dcount_' + product['category'][j].category_id ] = 1
                            if (typeof product['category'][j].slug != 'undefined') {
                              categories_ids[product['category'][j].slug] = counter_var
                              counter_var++
                            }
                          }
                          if (typeof categories_ids[product['category'][j].slug] != 'undefined') {
                            let obj = {'id': product['category'][j].category_id, 'value': product['category'][j].category_id, 'count' : vars['dcount_' + product['category'][j].category_id]++, 'label': product['category'][j].name} 
                            tmp[categories_ids[product['category'][j].slug]] = obj
                            // response['category_filter'].push(obj)
                          }
                        }
                      }
                      //PREPARE CATEGORY FILTER

                      //PREPARE COLOR FILTER
                      all_products[pro_key]['type_id'] = product['type_id']
                      if (typeof product['color_options'] !== 'undefined' && product['type_id'] == 'configurable') {
                        // console.log('all_products ', all_products[i])
                        all_products[pro_key]['color_options'] = product['color_options']
                        //AVAILABLE MULTIPLE COLORS
                        for (var cl = 0; cl < product['color_options'].length; cl++) {
                          let color_key = 'color_'+product['color_options'][cl]
                          if (typeof vars['dcount_' + product['color_options'][cl] ] == 'undefined') {
                            vars['dcount_' + product['color_options'][cl]] = 1
                            color_ids[color_key] = color_cnt_var
                            color_cnt_var++
                          }
                          if (typeof color_ids[color_key] != 'undefined') {
                            response['color_filter'][color_ids[color_key]] = {'id': product['color_options'][cl], 'value': product['color_options'][cl], 'count' : vars['dcount_' + product['color_options'][cl]]++, 'label': pro_attributes['color'][product['color_options'][cl]]} 
                          }
                        }
                      } else if (typeof product['color'] !== 'undefined') {
                        all_products[pro_key]['color_options'] = []
                        all_products[pro_key]['color_options'].push(parseInt(product['color']))
                        //AVAILABLE SINGLE COLORS
                        let color_key = 'color_'+product['color']
                        if (typeof vars['dcount_' + product['color'] ] == 'undefined') {
                          vars['dcount_' + product['color']] = 1
                          color_ids[color_key] = color_cnt_var
                          color_cnt_var++
                        }
                        if (typeof color_ids[color_key] != 'undefined') {
                          response['color_filter'][color_ids[color_key]] = {'id': product['color'], 'value': product['color'], 'count' : vars['dcount_' + product['color']]++, 'label': pro_attributes['color'][product['color']]} 
                        }
                      }
                      //PREPARE COLOR FILTER

                      //PREPARE SIZE FILTER
                      if (typeof product['size_options'] !== 'undefined' && product['type_id'] == 'configurable') {
                        all_products[pro_key]['size_options'] = product['size_options']
                        //AVAILABLE MULTIPLE SIZE
                        for (var cl = 0; cl < product['size_options'].length; cl++) {
                          let size_key = 'size_'+product['size_options'][cl]
                          if (typeof vars['dcount_' + product['size_options'][cl] ] == 'undefined') {
                            vars['dcount_' + product['size_options'][cl]] = 1
                            size_ids[size_key] = size_cnt_var
                            size_cnt_var++
                          }
                          if (typeof size_ids[size_key] != 'undefined') {
                            response['size_filter'][size_ids[size_key]] = {'id': product['size_options'][cl], 'value': product['size_options'][cl], 'count' : vars['dcount_' + product['size_options'][cl]]++, 'label': pro_attributes['size'][product['size_options'][cl]]} 
                          }
                        }
                      } else if (typeof product['size'] !== 'undefined') {
                        all_products[pro_key]['size_options'] = []
                        all_products[pro_key]['size_options'].push(parseInt(product['size']))
                        //AVAILABLE SINGLE SIZE
                        let size_key = 'size_'+product['size']
                        if (typeof vars['dcount_' + product['size'] ] == 'undefined') {
                          vars['dcount_' + product['size']] = 1
                          size_ids[size_key] = size_cnt_var
                          size_cnt_var++
                        }
                        if (typeof size_ids[size_key] != 'undefined') {
                          response['size_filter'][size_ids[size_key]] = {'id': product['size'], 'value': product['size'], 'count' : vars['dcount_' + product['size']]++, 'label': pro_attributes['size'][product['size']]} 
                        }
                      }
                      //PREPARE SIZE FILTER

                      //ADD RATING DATA
                      if (typeof product['extension_attributes']['rating'] !== 'undefined') {
                        all_products[pro_key]['rating'] = product['extension_attributes']['rating']
                      }
                      //ADD RATING DATA
                      pro_key++
                    } 
                  }
                }
              }
            }
            let test = []
            response['category_filter'] = new Array()
            for (const [key, val] of Object.entries(tmp)) {
              test.push(val)
            }
            //TODO::STORE ALL PRODUCTS WITH KM, NO PAGINATION
            response['category_filter'] = test
            if(pro_price.length > 0){
              // pro_price = [...new Set(pro_price)]
              let min_price = Math.min.apply(Math, pro_price)
              let max_price = Math.max.apply(Math, pro_price)
              var priceFilters = {}
              var first_label = 0
              var one_dcount = 0, two_dcount = 0, three_dcount = 0, four_dcount = 0, five_dcount = 0
              //CALCULATE PRICE INTERVAL
              price_interval = Math.round(max_price - min_price)
              price_interval = Math.round(price_interval / 5)
              if(price_interval.toString().length > 1){
                // let lastNum = price_interval / 10
                var toText = price_interval.toString();
                var lastChar = toText.slice(-1);
                var lastNum = +(lastChar);
                price_interval = price_interval - lastNum
              }
              first_label = min_price + price_interval
              let sec_label = first_label + price_interval
              let third_label = sec_label + price_interval
              let fourth_label = third_label + price_interval
              for (var i = 0; i < pro_price.length; i++) {
                let price = parseFloat(pro_price[i])

                if(price >= 0 && price < first_label){
                  one_dcount++
                }
                if(price >= first_label && price < sec_label){
                  two_dcount++
                }
                if(price >= sec_label && price < third_label){
                  three_dcount++
                }
                if(price >= third_label && price < fourth_label){
                  four_dcount++
                }
                if(price >= fourth_label){
                  five_dcount++
                }
              }
              response['price_filter'] = []
              if (price_interval == 0) {
                let priceAttr = {'count':five_dcount, 'from':fourth_label, 'to':0 , 'id':5, 'label':"> ₹"+fourth_label }
                response['price_filter'][0] = priceAttr
              } else {
                let priceAttr = {'count':one_dcount, 'from':0, 'to':first_label , 'id':1, 'label':'< ₹'+first_label }
                response['price_filter'][0] = priceAttr
                priceAttr = {'count':two_dcount, 'from':first_label, 'to':sec_label , 'id':2, 'label':"₹"+first_label+" - ₹"+sec_label }
                response['price_filter'][1] = priceAttr
                priceAttr = {'count':three_dcount, 'from':sec_label, 'to':third_label , 'id':3, 'label':"₹"+sec_label+" - ₹"+third_label }
                response['price_filter'][2] = priceAttr
                priceAttr = {'count':four_dcount, 'from':third_label, 'to':fourth_label , 'id':4, 'label':"₹"+third_label+" - ₹"+fourth_label }
                response['price_filter'][3] = priceAttr
                priceAttr = {'count':five_dcount, 'from':fourth_label, 'to':0 , 'id':5, 'label':"> ₹"+fourth_label }
                response['price_filter'][4] = priceAttr
              }
            }
            response['total_min_km_pro'] = total_min_km_pro
          }
          return response
      })
    }
  },
  getSellerFilter (context, {id, sort, query, page, limit}) {
    let url = (config.seller.endpoint) + '/sellerfilter/{{id}}/{{sort}}/{{query}}/{{page}}/{{limit}}'.replace('{{id}}', id).replace('{{sort}}', sort).replace('{{query}}', query).replace('{{page}}', page).replace('{{limit}}', limit)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellersFilter`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },
  getBestSellerFilter (context, {id, sort, query, page, limit}) {
    let url = (config.seller.endpoint) + '/bestsellerFilter/{{id}}/{{sort}}/{{query}}/{{page}}/{{limit}}'.replace('{{id}}', id).replace('{{sort}}', sort).replace('{{query}}', query).replace('{{page}}', page).replace('{{limit}}', limit)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`bestSellersFilter`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },

  getSellerReviewListing (context, {seller_id}) {
    let url = (config.seller.endpoint) + '/sellerReviewList/{{seller_id}}'.replace('{{seller_id}}', seller_id)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`sellerReviewListing`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },

  /**
   * Add new review
   *
   * @param context
   * @param {Review} reviewData
   * @returns {Promise<void>}
   */
  async addSellerReview (context, reviewData: Review) {
    const review: ReviewRequest = {review: reviewData}
    let url = (config.seller.endpoint) + '/addSellerReview'

    // Vue.prototype.$bus.$emit('notification-progress-start', i18n.t('Adding a review ...'))

    // if (config.storeViews.multistore) {
    //   url = adjustMultistoreApiUrl(url)
    // }

    try {
      await fetch(processURLAddress(url), {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(review)
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 200) {
            if (data.result[0].data) {
              Vue.prototype.$bus.$emit('notification-progress-stop')
              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: i18n.t('You submitted your review for moderation.'),
                action1: { label: i18n.t('OK') }
              })
              Vue.prototype.$bus.$emit('hideReviewForm')
            } else if (data.result[0].msg) {
              // Vue.prototype.$bus.$emit('notification-progress-stop')
              rootStore.dispatch('notification/spawnNotification', {
                type: 'error',
                message: i18n.t(data.result[0].msg),
                action1: { label: i18n.t('OK') }
              })
            }
          } else {
            // Vue.prototype.$bus.$emit('notification-progress-stop')
            rootStore.dispatch('notification/spawnNotification', {
              type: 'error',
              message: i18n.t('Something went wrong. Try again in a few seconds.'),
              action1: { label: i18n.t('OK') }
            })
          }
        })

      Vue.prototype.$bus.$emit('clear-add-review-form')
    } catch (e) {
      // Vue.prototype.$bus.$emit('notification-progress-stop')
      /* rootStore.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Something went wrong. Try again in a few seconds.'),
        action1: { label: i18n.t('OK') }
      }) */
    };
  },

  checkUserSellerProductOrder (context, {seller_id, buyer_id}) {
    let url = (config.seller.endpoint) + '/isbuyergivereview/{{buyer_id}}/{{seller_id}}'.replace('{{seller_id}}', seller_id).replace('{{buyer_id}}', buyer_id)
    fetch(processURLAddress(url), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          context.commit(`IsUserAllowForReview`, data.result)
        }
      })
      .catch((err) => {
        Logger.log(err)()
        Logger.error(err)()
      })
  },

  setSellerProFilterLoader (context, loader) {
    context.commit('updateSellerProductsLoader', loader)
  }

}

export default actions
