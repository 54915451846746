var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v(
      "This page is rendered with no JavaScripts attached - minimal layout (for example a base for AMPHTML)"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }