import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import DoyenhubSellerState from '../types/DoyenhubSellerState'

const getters: GetterTree<DoyenhubSellerState, RootState> = {
  getSellerInfo: state => {
    return state.sellerInfo
  },
  getSellerProducts: state => {
    return state.sellerProducts
  },
  getSellerProductsFilter: state => {
    return state.sellerProductsFilter
  },
  getSellerFilter: state => {
    return state.sellersFilter
  },
  getBestSellerFilter: state => {
    return state.bestSellersFilter
  },
  getSellerReviewListing: state => {
    return state.sellerReviewListing
  },
  checkUserSellerProductOrder: state => {
    return state.IsUserAllowForReview
  },
  breadcrumbs: (state) => state.breadcrumbs
}

export default getters
