import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import DoyenhubSellerState from '../types/DoyenhubSellerState'

export const module: Module<DoyenhubSellerState, RootState> = {
  namespaced: true,
  state: {
    sellerInfo: [],
    sellerProducts: [],
    sellerProductsFilter: [],
    sellersFilter: [],
    bestSellersFilter: [],
    sellerReviewListing: [],
    IsUserAllowForReview: [],
    breadcrumbs: [],
    sellerProductsFilterLoad: false
  },
  getters,
  actions,
  mutations
}
