var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-option mb-5" },
    [
      _c("h4", { staticClass: "mb-2" }, [
        _vm._v("\n    " + _vm._s(_vm.option.title) + "\n  "),
      ]),
      _vm._v(" "),
      _vm._l(_vm.option.product_links, function (opval) {
        return _c("div", { key: opval.id, staticClass: "relative" }, [
          _c("div", { staticClass: "checkbox-wrap" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productOptionId,
                  expression: "productOptionId",
                },
              ],
              attrs: {
                type: "radio",
                name: _vm.bundleOptionName + opval.id,
                id: _vm.bundleOptionName + opval.id,
                focus: "",
              },
              domProps: {
                value: opval.id,
                checked: _vm._q(_vm.productOptionId, opval.id),
              },
              on: {
                change: function ($event) {
                  _vm.productOptionId = opval.id
                },
              },
            }),
            _vm._v(" "),
            opval.product
              ? _c("label", {
                  attrs: { for: _vm.bundleOptionName + opval.id },
                  domProps: { innerHTML: _vm._s(opval.product.name) },
                })
              : _vm._e(),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("qty-input", {
        staticClass: "mb-2",
        attrs: { id: _vm.quantityName, name: _vm.quantityName },
        model: {
          value: _vm.quantity,
          callback: function ($$v) {
            _vm.quantity = _vm._n($$v)
          },
          expression: "quantity",
        },
      }),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.errorMessage)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }