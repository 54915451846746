import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import UserState from '../types/UserState'
import { Logger } from '@vue-storefront/core/lib/logger'

const mutations: MutationTree<UserState> = {
  [types.USER_TOKEN_CHANGED] (state, payload) {
    state.token = payload.newToken
    if (payload.meta && payload.meta.refreshToken) {
      state.refreshToken = payload.meta.refreshToken // store the refresh token
      Logger.log('Refresh token is set to' + state.refreshToken, 'user')()
    }
  },
  [types.USER_START_SESSION] (state) {
    state.session_started = new Date()
  },
  [types.USER_GROUP_TOKEN_CHANGED] (state, token) {
    state.groupToken = token
  },
  [types.USER_GROUP_CHANGED] (state, groupId) {
    state.groupId = groupId
  },
  [types.USER_INFO_LOADED] (state, currentUser) {
    state.current = currentUser
  },
  [types.USER_ORDERS_HISTORY_LOADED] (state, ordersHistory) {
    state.orders_history = ordersHistory
  },
  [types.USER_RETURNED_ORDERS] (state, returned_order) {
    state.returned_orders = returned_order
  },
  [types.USER_END_SESSION] (state) {
    state.token = ''
    state.current = null
    state.session_started = null
  },
  [types.USER_LOCAL_DATA_LOADED] (state, readed = false) {
    state.local_data_loaded = readed
  },
  [types.USER_CURRENT_LOCATION] (state, location) {
    state.current_user_location = location
  },
  [types.USER_CURRENT_SHORT_LOCATION] (state, short_location) {
    state.current_user_short_location = short_location
  },
  [types.USER_CURRENT_LATITUDE] (state, latitude) {
    state.current_user_latitude = latitude
  },
  [types.USER_CURRENT_LONGITUDE] (state, longitude) {
    state.current_user_longitude = longitude
  },
  [types.USER_RMA_ORDERS] (state, rmaOrders) {
    state.rmaOrders = rmaOrders
  },
  set_user_shipping_addresses (state, addresses) {
    state.shipping_addresses = addresses
  }
}

export default mutations
