import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import BestSellerState from '../types/BestSellerState'

export const module: Module<BestSellerState, RootState> = {
  namespaced: true,
  state: {
    allShops: [],
    initialMinimumKm: [],
    filters: {
      availablefilter: {},
      chosen: {}
    },
  },
  getters,
  actions,
  mutations
}
