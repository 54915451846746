import Vue from 'vue'
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import BestSellerState from '../types/BestSellerState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { processURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import fetch from 'isomorphic-fetch'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import i18n from '@vue-storefront/i18n'
import rootStore from '@vue-storefront/core/store'
import { ReviewRequest } from '@vue-storefront/core/modules/review/types/ReviewRequest'
import Review from '@vue-storefront/core/modules/review/types/Review'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'

const actions = {
  
  addAvailableFilter (context, {key = {}, options = {}}) {
    if (key) context.commit(`addAvailableShopFilters`, {key, options})
  },
  setInitialMinimumKm (context, initialMinimumKm) {
    context.commit(`setInitialMinimumKm`, initialMinimumKm)
  }
}

export default actions
